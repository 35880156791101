import * as React from 'react';
import SliderCarousel from '../../organisms/SliderCarousel/SliderCarousel';
import { css } from '@emotion/react';
import Text from '../../atoms/Text/Text';

export default function SliderInicio({ data, titlePage }) {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      tabIndex="-1"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="7px"
          points="35.86 3.5 4.95 34.41 34.51 63.97"
        />
      </svg>
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      tabIndex="-1"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="7px"
          points="3.5 3.5 34.41 34.41 4.84 63.97"
        />
      </svg>
    </button>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // autoplay: false,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: true,
    touchMove: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };

  const sliderCss = css`
    & .slick-next {
      right: calc(100vw * (calc(150 / var(--width_base))));
      @media (max-width: 767px) {
        right: calc(100vw * (calc(10 / var(--width_base))));
      }
    }
    & .slick-prev {
      left: calc(100vw * (calc(150 / var(--width_base))));
      @media (max-width: 767px) {
        left: calc(100vw * (calc(10 / var(--width_base))));
      }
    }
  `;

  return (
    <section>
      {titlePage && (
        <Text classEle="d-none" data={`Banner ${titlePage}`} typeEle="h2" />
      )}
      <SliderCarousel
        data={data}
        settings={settings}
        typeStyle="banner-inicio"
        styleCss={sliderCss}
      />
    </section>
  );
}
