import * as React from 'react';
import SliderItemStyle4 from './SliderItemStyle4';
import SliderItemPredeterminado from './SliderItemPredeterminado';

export default function SliderItemBanner({ data }) {
  return (
    <>
      {data?.estilo === 'estilo4' ? (
        <SliderItemStyle4 data={data} />
      ) : (
        <SliderItemPredeterminado data={data} />
      )}
    </>
  );
}
