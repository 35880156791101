import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/react';
import Text from '../../atoms/Text/Text';
import GrillasItemNormalProyectos from './GrillasItemNormalProyectos';
import GrilllasItemSliderProyectos from './GrilllasItemSliderProyectos';
// import { useCallback, useEffect, useState } from "react"
// import { Link } from "gatsby"

export default function GrillaProyectos({
  title,
  subtitle,
  titleCss,
  subTitleCss,
  colorText,
  typeEle,
}) {
  subTitleCss = css`
    ${subTitleCss}
    & * {
      text-align: center;
    }
  `;

  const data = useStaticQuery(graphql`
    {
      wordpress {
        proyectos(first: 40) {
          nodes {
            title
            uri
            featuredImage {
              node {
                ...imagen
              }
            }
            distritos {
              nodes {
                databaseId
                name
              }
            }
            estados {
              nodes {
                databaseId
                name
              }
            }
            dormitorios {
              nodes {
                databaseId
                name
              }
            }
            template {
              ... on Wordpress_Template_ProyectoEnVenta {
                proyectoEnVenta {
                  logoPortada {
                    ...imagen
                  }
                  direccionDescripcion
                  lanzamientoDescripcion
                  precioDescripcion
                }
              }
            }
          }
        }

        proximosProyectos(first: 40) {
          nodes {
            title
            uri
            featuredImage {
              node {
                ...imagen
              }
            }
            distritos {
              nodes {
                databaseId
                name
              }
            }
            template {
              ... on Wordpress_Template_ProximoProyecto {
                proximoProyecto {
                  logoPortada {
                    ...imagen
                  }
                  direccionDescripcion
                  lanzamientoDescripcion
                }
              }
            }
            estados {
              nodes {
                databaseId
                name
                slug
              }
            }
            dormitorios {
              nodes {
                databaseId
                name
              }
            }
          }
        }
      }
    }
  `);

  // const [proyectos, setProyectos] = useState(data?.wordpress?.proyectos?.nodes);
  // const [proximosProyectos, setProximosProyectos] = useState(data?.wordpress?.proximosProyectos?.nodes);

  const proyectos = data?.wordpress?.proyectos?.nodes;
  const proximosProyectos = data?.wordpress?.proximosProyectos?.nodes;

  // const filtrar = useCallback((distrito, estado, proyecto) => {
  //   let newProyectos = [...proyectos];
  //   let newProximos = [...proximosProyectos];
  //   if(distrito !== "") newProyectos = newProyectos.filter((proyectoM) => proyectoM?.distritos?.nodes[0]?.name.toLowerCase() === distrito);
  //   if(distrito !== "") newProximos = newProximos.filter((proyectoM) => proyectoM?.distritos?.nodes[0]?.name.toLowerCase() === distrito);
  //   if(distrito !== ""){
  //     setProyectos(newProyectos);
  //     setProximosProyectos(newProximos);
  //   }
  //   },[proyectos,proximosProyectos]);

  // useEffect(() => {
  //   const dataDistrito = localStorage.getItem("distrito");
  //   const dataEstado = localStorage.getItem("estado");
  //   const dataProyecto = localStorage.getItem("proyecto");
  //   filtrar(dataDistrito, dataEstado, dataProyecto);
  // }, [filtrar])

  const containerCss = css`
    background-color: #5e5e5e;
    padding-top: calc(100vw * (calc(40 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(25 / var(--width_base))));

    @media (max-width: 767px) {
      background-color: #444443;
      padding-top: calc(100vw * (calc(25 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(12 / var(--width_base))));
    }
  `;

  const textNoHayResultados = css`
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
  `;

  return (
    <div css={containerCss} className="">
      {title && (
        <Text
          data={title}
          styleCss={titleCss}
          align="center"
          colorText={colorText}
          data-aos="fade-right"
          data-aos-delay="200"
        />
      )}
      {/* { subtitle && <Text data={subtitle} styleCss={subTitleCss} align="center" colorText={colorText} data-aos="fade-right" data-aos-delay="300"/>} */}
      {subtitle && (
        <div css={subTitleCss} data-aos="fade-right" data-aos-delay="300">
          <Text data={subtitle} />
        </div>
      )}
      {proyectos.length > 0 || proximosProyectos.length > 0 ? (
        <>
          {typeEle === 'slider' ? (
            <GrilllasItemSliderProyectos
              dataProyectos={proyectos}
              dataProximosProyectos={proximosProyectos}
            />
          ) : (
            <GrillasItemNormalProyectos
              dataProyectos={proyectos}
              dataProximosProyectos={proximosProyectos}
            />
          )}
        </>
      ) : (
        <Text
          data="No hay resultados"
          align="center"
          styleCss={textNoHayResultados}
          colorText="#999"
          data-aos="fade-right"
          data-aos-delay="200"
        />
      )}
    </div>
  );
}

GrillaProyectos.defaultProps = {
  typeEle: 'grilla',
};
