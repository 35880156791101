import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/react"
import Text from "../../atoms/Text/Text"
import GrillasItemNormalProyectos from "./GrillasItemNormalProyectos"
import GrilllasItemSliderProyectos from "./GrilllasItemSliderProyectos"
import { useCallback, useEffect, useState } from "react"
import { Link } from "gatsby"

export default function GrillaProximosProyectos({
  title,
  subtitle,
  titleCss,
  subTitleCss,
  colorText,
  typeEle,
}) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        proximosProyectos(first: 40) {
          nodes {
            title
            uri
            featuredImage {
              node {
                ...imagen
              }
            }
            distritos {
              nodes {
                name
              }
            }
            template {
              ... on Wordpress_Template_ProximoProyecto {
                proximoProyecto {
                  logoPortada {
                    ...imagen
                  }
                  direccionDescripcion
                  lanzamientoDescripcion
                }
              }
            }
            estados {
              nodes {
                name
                slug
              }
            }
            dormitorios {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `)

  const [proximosProyectos, setProximosProyectos] = useState(
    data?.wordpress?.proximosProyectos?.nodes
  )

  const filtrar = useCallback(
    (distrito, estado, proyecto) => {
      let newProximos = [...proximosProyectos]
      if (distrito !== "")
        newProximos = newProximos.filter(
          proyectoM =>
            proyectoM?.distritos?.nodes[0]?.name.toLowerCase() === distrito
        )
      if (estado !== "")
        newProximos = newProximos.filter(
          proyectoM =>
            proyectoM?.estados?.nodes[0]?.name.toLowerCase() === estado
        )
      if (proyecto !== "")
        newProximos = newProximos.filter(
          proyectoM => proyectoM?.title.toLowerCase() === proyecto
        )

      if (distrito !== "" || estado !== "" || proyecto !== "") {
        setProximosProyectos(newProximos)
      }
    },
    [proximosProyectos]
  )

  useEffect(() => {
    const dataDistrito = localStorage.getItem("distrito")
    const dataEstado = localStorage.getItem("estado")
    const dataProyecto = localStorage.getItem("proyecto")
    filtrar(dataDistrito, dataEstado, dataProyecto)
  }, [filtrar])

  const containerCss = css`
    background-color: #5e5e5e;
    padding-top: calc(100vw * (calc(40 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(25 / var(--width_base))));

    @media (max-width: 767px) {
      background-color: #444443;
      padding-top: calc(100vw * (calc(25 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(12 / var(--width_base))));
    }
  `

  const textNoHayResultados = css`
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
  `

  return (
    <div css={containerCss}>
      {title && (
        <Text
          data={title}
          styleCss={titleCss}
          align="center"
          colorText={colorText}
          data-aos="fade-right"
          data-aos-delay="200"
        />
      )}
      {/* { subtitle && <Text data={subtitle} styleCss={subTitleCss} align="center" colorText={colorText} data-aos="fade-right" data-aos-delay="300"/>} */}
      {subtitle && (
        <div css={subTitleCss} data-aos="fade-right" data-aos-delay="300">
          <Link
            to="/departamentos-venta/"
            css={css`
              color: white;
            `}
          >
            EN VENTA
          </Link>{" "}
          |{" "}
          <Link
            css={css`
              color: white;
            `}
            to="/proximos-proyectos/"
          >
            PROXIMOS LANZAMIENTOS
          </Link>
        </div>
      )}
      {proximosProyectos.length > 0 ? (
        <>
          {typeEle === "slider" ? (
            <GrilllasItemSliderProyectos
              dataProximosProyectos={proximosProyectos}
            />
          ) : (
            <GrillasItemNormalProyectos
              dataProximosProyectos={proximosProyectos}
            />
          )}
        </>
      ) : (
        <Text
          data="No hay resultados"
          align="center"
          styleCss={textNoHayResultados}
          colorText="#999"
          data-aos="fade-right"
          data-aos-delay="400"
        />
      )}
    </div>
  )
}

GrillaProximosProyectos.defaultProps = {
  typeEle: "grilla",
}

