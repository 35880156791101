import * as React from "react"
import Image from "../../atoms/Image/Image"
import { css } from "@emotion/react"
import Text from "../../atoms/Text/Text"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function CardAvanceObraProyecto ({keyEle, data, isProyecto, imageEleCss, classEle}) {

  let urlData = data?.template?.proyectoEnVenta;

  if(!isProyecto){
    urlData = data?.template?.proximoProyecto;
  }

  const imageCss = css`
    height: calc(100vw * (calc(435 / var(--width_base))));
    width: 100%;
    @media (max-width: 767px) {
      height: calc(100vw * (calc(400 / var(--width_base))));          
    }
    ${imageEleCss};
  `;

  const cardCss = css`
    position: relative;
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    color: white;
    display: block;
    
    @media (max-width: 767px) {
      margin-bottom: initial;          
    }
  `;

  const estadoCss = css`
    font-family: "Montserrat Bold",sans-serif;
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(13 / var(--width_base))));
    opacity: .8;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(100vw * (calc(250 / var(--width_base))));
    z-index: 1;
    background : -moz-linear-gradient(50% -15.24% -90deg,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 1) 100%);
    background : -webkit-linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background : -webkit-gradient(linear,50% -15.24% ,50% 104.78% ,color-stop(0,rgba(255, 255, 255, 0) ),color-stop(1,rgba(0, 0, 0, 1) ));
    background : -o-linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background : -ms-linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#000000' ,GradientType=0)";
    background : linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=0);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    @media (max-width: 767px) {
      text-transform: uppercase;
    }
  `;

  const contentLogoCss = css`
    position: absolute;
    top: calc(100vw * (calc(30 / var(--width_base))));
    left: calc(100vw * (calc(35 / var(--width_base))));
  `;
  
  const logoCss = css`
    height: calc(100vw * (calc(25 / var(--width_base))));
    width: initial;
  `;

  return(
    <Link key={keyEle} css={cardCss} to={data?.uri ? data?.uri : '/'} className={`card-proyecto text-center ${classEle}`}>
      {
        data?.featuredImage?.node ?
          <Image imagen={data?.featuredImage?.node} styleCss={imageCss} classEle="card-imagen"/>
          :
          <StaticImage
          css={imageCss}
          src="../../../images/sin-imagen.png"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Proyecto sin imagen"
          />
      }
      {urlData?.logoPortada && <Image imagen={urlData?.logoPortada} styleCss={logoCss}  styleContentCss={contentLogoCss} objectFit="contain"/>}
      {data?.estados?.nodes[0]?.name && <Text styleCss={estadoCss} data={data?.estados?.nodes[0]?.name} classEle="card-estado"/>}
    </Link>
  )
}

CardAvanceObraProyecto.defaultProps = {
  isProyecto : true
}