import * as React from "react"
import { useRef } from "react"
import { navigate } from "gatsby"
import Search from "../../moleculas/Search/Search"
import Text from "../../atoms/Text/Text"
// import { parserString } from "../../../helper/parserString"

export default function SearchInicio({ titlePage, ...others }) {
  const selectDistrito = useRef(null)
  const selectEstado = useRef(null)
  const selectProyecto = useRef(null)

  const handleSearch = () => {
    localStorage.setItem("distrito", selectDistrito?.current?.value)
    navigate("/departamentos-venta/")
  }

  return (
    <section {...others}>
      {titlePage && (
        <Text classEle="d-none" data={`Buscador ${titlePage}`} typeEle="h2" />
      )}
      <Search
        selectDistrito={selectDistrito}
        selectProyecto={selectProyecto}
        selectEstado={selectEstado}
        eventEle={handleSearch}
      />
    </section>
  )
}
