import './src/components/_settings/Base/Base.css';
import './src/components/_settings/Base/Others.css';
import './src/components/_settings/Theme/Variables.css';
import './src/components/_settings/Theme/Fonts.css';
import './src/components/_settings/Layout/Grid.css';
import './src/components/_settings/Components/utilities/Width.css';
import './src/components/_settings/Components/utilities/Margin.css';
import './src/components/_settings/Components/utilities/Height.css';
import './src/components/_settings/Components/utilities/Text.css';
import './src/components/_settings/Components/utilities/Display.css';
import './src/components/_settings/Components/utilities/Overflow.css';
import './src/components/_settings/Components/Slider.css';
import './src/components/_settings/Components/Icon.css';
import './src/components/_settings/Components/Form.css';
import './src/components/_settings/State/Visibility.css';
import './src/components/_settings/State/Scroll.css';