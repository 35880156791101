import * as React from "react"
import GrillaProyectos from "../../organisms/Proyectos/GrillaProyectos"
import { css } from "@emotion/react"
import GrillaProximosProyectos from "../../organisms/Proyectos/GrillaProximosProyectos"
import Text from "../../atoms/Text/Text"

export default function  NuestrosProyectos({title, subtitle, typeStyle,isEnVenta, titlePage, ...others}) {

  let titleCss = {};
  let subTitleCss = {};
  let colorText = null;

  switch (typeStyle) {
    case "home":
      titleCss = css`
        font-size: calc(100vw * (calc(26 / var(--width_base))));
        line-height: calc(100vw * (calc(26 / var(--width_base))));
        font-family: "Montserrat Bold", sans-serif;
        margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
      `;
      colorText = "white";
    break;
    case "page":
      titleCss = css`
        font-size: calc(100vw * (calc(23 / var(--width_base))));
        line-height: calc(100vw * (calc(23 / var(--width_base))));
        margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
        font-family: "Montserrat Bold", sans-serif;
        @media (max-width: 767px) {
          font-size: calc(100vw * (calc(24 / var(--width_base))));
          line-height: calc(100vw * (calc(24 / var(--width_base))));
          margin-bottom: calc(100vw * (calc(23 / var(--width_base))));
          color: #999;
        }
      `;
      subTitleCss = css`
        font-size: calc(100vw * (calc(21 / var(--width_base))));
        line-height: calc(100vw * (calc(21 / var(--width_base))));
        margin-bottom: calc(100vw * (calc(37 / var(--width_base))));
        text-align: center;
        color: white;
        @media (max-width: 767px) {
          font-size: calc(100vw * (calc(17 / var(--width_base))));
          line-height: calc(100vw * (calc(17 / var(--width_base))));
          color: #999;
          margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
        }
      `;
        colorText = "white";
      break;
    default:
      colorText = "white";
    break;
  }

  return(
    <section {...others}>
      {titlePage && <Text classEle="d-none" data={`Nuestros proyectos ${titlePage}`} typeEle="h2"/>}
      {
        (isEnVenta) ?
          <GrillaProyectos title={title} subtitle={subtitle} titleCss={titleCss} subTitleCss={subTitleCss} colorText={colorText}/>
        :
          <GrillaProximosProyectos title={title} subtitle={subtitle} titleCss={titleCss} subTitleCss={subTitleCss} colorText={colorText}/>
      }
    </section>
  )
}


NuestrosProyectos.defaultProps = {
  typeStyle : "page",
  isEnVenta: true
}