import * as React from 'react';
import { useEffect, useState } from 'react';
import { drupalLogout } from '../../../api/AuthenticationEvolta';
import { getPrice } from '../../../api/GetPriceEvolta';
import ReactModal from 'react-modal';
import BotonesProyecto from './BotonesProyecto';
import CotizarForm from '../Form/CotizarForm';
import { css } from '@emotion/react';
// import Icon from "../../atoms/Icon/Icon"
// import Button from "../../atoms/Button/Button"

if (typeof window !== 'undefined') ReactModal.setAppElement('body');

export default function CotizadorProyecto({
  siteUrl,
  idProyecto,
  proyecto,
  correoEvolta,
  paginaGracias,
  arrNoInmuebles,
}) {
  const [starsCount, setStarsCount] = useState([]);
  const [nroDorm, setNroDorm] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelo, setModelo] = useState('');
  const [codInmueble, setCodInmueble] = useState('');
  const [cantDormitorios, setCantDormitorios] = useState('');
  const [banios, setBanios] = useState('');
  const [precio, setPrecio] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      let resultados = JSON.parse(localStorage.getItem(`data-${idProyecto}`));
      if (!(resultados && resultados === '')) {
        resultados = await drupalLogout(idProyecto);
        localStorage.setItem(`data-${idProyecto}`, JSON.stringify(resultados));
      }

      if (resultados !== 'No se encuentran registros.') {
        let datos = Object.values(resultados),
          dorm = [],
          newDatos = [];
        const datosFiltrados = datos.filter(
          (dato) => !arrNoInmuebles.includes(dato.modelo)
        );
        datosFiltrados.map((dato) => (dorm = [...dorm, dato.nroDormitorio]));
        const nroDorm = dorm
          .filter((valor, indice) => dorm.indexOf(valor) === indice)
          .sort();
        nroDorm.map(
          (nro) =>
            (newDatos = [
              ...newDatos,
              datosFiltrados.filter((dato) => dato.nroDormitorio === nro),
            ])
        );
        setNroDorm(nroDorm);
        setStarsCount(newDatos);
        console.log('nroDorm', nroDorm);
        console.log('newDatos', newDatos);
        const first = newDatos[0][0];
        console.log('first', first);
        setCodInmueble(first.codInmueble);
        setModelo(first.modelo);
      }
    };
    fetchData();
    return () => {
      setNroDorm('');
    };
  }, [idProyecto, arrNoInmuebles]);

  const handleModalOpen = async (modelo, cod, cantDormitorios, banios) => {
    // setIsModalOpen(true);
    setModelo(modelo);
    setCodInmueble(cod);
    setCantDormitorios(cantDormitorios);
    setBanios(banios);
    const precioVenta = await getPrice(cod);
    setPrecio(precioVenta);
  };

  // const handleModalClose = event => {
  //   setIsModalOpen(false)
  // }

  const contentFormCss = css`
    width: calc(100vw * (calc(500 / var(--width_base))));
    @media (max-width: 767px) {
      width: 100%;
      margin-top: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  // const closeCss = css`
  //   margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
  // `;

  const contentModalCss = css`
    background-color: #4a4a49;
    width: auto;
    margin: auto;
    padding: calc(100vw * (calc(15 / var(--width_base))));
  `;

  const contentFlexCss = css`
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `;
  return (
    <div css={contentFlexCss}>
      {nroDorm?.length > 0 && (
        <BotonesProyecto
          setCodInmueble={setCodInmueble}
          setModelo={setModelo}
          nroDorm={nroDorm}
          data={starsCount}
          handleEventButton={handleModalOpen}
        />
      )}
      <div css={contentModalCss}>
        {/* <div className="text-right">
            <Button eventEle={handleModalClose} styleEle={closeCss} typeEle="button"><Icon typeEle="svg-close"/></Button>
          </div> */}
        <div css={contentFormCss}>
          <CotizarForm
            siteUrl={siteUrl}
            nomProyecto={proyecto}
            codInmueble={codInmueble}
            modelo={modelo}
            correoEvolta={correoEvolta}
            paginaGracias={paginaGracias}
            dormitorios={cantDormitorios}
            banios={banios}
            precio={precio}
          />
        </div>
      </div>
      {/* <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Modal Cotizar"
      >
        <div css={contentModalCss}>
          <div className="text-right">
            <Button eventEle={handleModalClose} styleEle={closeCss} typeEle="button"><Icon typeEle="svg-close"/></Button>
          </div>
          <div css={contentFormCss}>
            <CotizarForm
              siteUrl={siteUrl}
              nomProyecto={proyecto}
              codInmueble={codInmueble}
              modelo={modelo}
              correoEvolta={correoEvolta}
              paginaGracias={paginaGracias}
              dormitorios={cantDormitorios}
              banios={banios}
              precio={precio}
              />
          </div>
        </div>
      </ReactModal> */}
    </div>
  );
}
