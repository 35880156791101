import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import { css } from '@emotion/react';
import MapaMarker from '../../moleculas/MapaMarker/MapaMarker';
import Icon from '../../atoms/Icon/Icon';
import Text from '../../atoms/Text/Text';

const estilos = [
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 13,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#144b53',
      },
      {
        lightness: 14,
      },
      {
        weight: 1.4,
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#08304b',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#0c4152',
      },
      {
        lightness: 5,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#0b434f',
      },
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#0b3d51',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        color: '#146474',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#021019',
      },
    ],
  },
];
export default function MapaProyecto({ latitud, longitud, data, titlePage }) {
  let latitudRef = data[0]?.ubicacion?.latitude;
  let longitudRef = data[0]?.ubicacion?.longitude;

  const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: estilos,
    };
  };

  const contentMapCss = css`
    height: 100vh;
    width: 100%;
    min-height: calc(100vw * (calc(800 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(127 / var(--width_base))));
    background-color: #444443;
    @media (max-width: 767px) {
      background-color: #444443;
      min-height: initial;
      height: calc(100vw * (calc(400 / var(--width_base))));
      padding-bottom: initial;
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  return (
    <section css={contentMapCss}>
      {titlePage && (
        <Text classEle="d-none" data={`Mapa ${titlePage}`} typeEle="h2" />
      )}
      {latitudRef && longitudRef && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDphecoYppv9HH7Q8zXUVPaGOrfLLu-Kjc' }}
          defaultCenter={{
            lat: parseFloat(latitudRef),
            lng: parseFloat(longitudRef),
          }}
          defaultZoom={17}
          options={createMapOptions}
        >
          <MapaMarker
            lat={parseFloat(latitudRef)}
            lng={parseFloat(longitudRef)}
            text="Icono Proyecto"
          >
            <Icon typeEle="marker-logo" />
          </MapaMarker>
          {/* {marcadores} */}
        </GoogleMapReact>
      )}
    </section>
  );
}
