import * as React from "react"
import GrillaProyectos from "../../organisms/Proyectos/GrillaProyectos"
import Text from "../../atoms/Text/Text"

export default function NuestrosSliderProyectos ({titlePage,...others}) {

  return(
    <section {...others}>
      {titlePage && <Text classEle="d-none" data={`Nuestros proyectos slider ${titlePage}`} typeEle="h2"/>}
      <GrillaProyectos typeEle="slider" />
    </section>
  )
}
