import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import NuestrosProyectos from "../components/template/Proyectos/NuestrosProyectos"
// import SearchProximosProyectos from "../components/template/ProximoProyecto/SearchProximosProyectos"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        template {
          ... on Wordpress_Template_ProximosProyectos {
            proximosProyectos {
              titulo
              subtitulo
            }
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function ProximosProyectos({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.proximosProyectos

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      {/* <SearchProximosProyectos/> */}
      <NuestrosProyectos
        title={template?.titulo}
        subtitle={template?.subtitulo}
        isEnVenta={false}
      />
    </LayoutGeneral>
  )
}

