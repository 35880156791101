import * as React from 'react';
import Text from '../../atoms/Text/Text';
import { css } from '@emotion/react';

export default function TrabajamosCon({ dataTitulo, dataImagenes }) {
  const sectionCss = css`
    background-color: #444443;
    padding-top: calc(100vw * (calc(40 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(40 / var(--width_base))));
    @media (max-width: 767px) {
      background-color: #444443;
      padding-left: calc(100vw * (calc(30 / var(--width_base))));
      padding-right: calc(100vw * (calc(30 / var(--width_base))));
      padding-top: calc(100vw * (calc(40 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

  const contentCss = css`
    max-width: calc(100vw * (calc(840 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(100vw * (calc(24 / var(--width_base))));
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(43 / var(--width_base))));
    font-family: 'Montserrat Bold', sans-serif;
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
      color: #999;
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
      margin-left: auto;
      margin-right: auto;
    }
  `;
  const imageCss = css`
    width: calc(100vw * (calc(160 / var(--width_base))));
    @media (max-width: 767px) {
      width: calc(100vw * (calc(80 / var(--width_base))));
    }
  `;

  return (
    <section css={sectionCss}>
      {dataTitulo && (
        <Text
          data={dataTitulo ? dataTitulo : ``}
          colorText="white"
          styleCss={titleCss}
          align="center"
          data-aos="fade-right"
          data-aos-delay="200"
        />
      )}
      <div css={contentCss}>
        {dataImagenes &&
          dataImagenes.map((imagen, i) => (
            <div key={i}>
              <img css={imageCss} src={imagen.sourceUrl} alt={imagen.altText} />
            </div>
          ))}
      </div>
    </section>
  );
}
