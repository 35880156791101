import * as React from "react"
import Image from "../../atoms/Image/Image"
import Text from "../../atoms/Text/Text"
import { css } from "@emotion/react";

export default function BestPlaceBeneficios ({imagen, imagenMovil, texto, titlePage}) {

  const textCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(24 / var(--width_base))));
    @media (max-width: 767px) {
                
    }
  `;

  const contentTextCss = css`
    background-color: #5E5E5E;
    padding-top: calc(100vw * (calc(51 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(61 / var(--width_base))));
  `;

  return(
    <section>
      {titlePage && <Text classEle="d-none" data={`Best place ${titlePage}`} typeEle="h2"/>}
      <Image imagen={imagen} classEle="only-desk"/>
      <Image imagen={imagenMovil} classEle="only-mov"/>
      <div css={contentTextCss} className="only-desk">
        <Text data={texto} styleCss={textCss} colorText="white" align="center" data-aos="fade-right" data-aos-delay="200"/>
      </div>
    </section>
  )
}
