import React from 'react';
import { graphql } from 'gatsby';
import LayoutGeneral from '../components/organisms/Layout/LayoutGeneral';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import SliderInicio from '../components/template/Inicio/SliderInicio';
import NuestraInicio from '../components/template/Inicio/NuestraInicio';
import NuestrosProyectos from '../components/template/Proyectos/NuestrosProyectos';
import TabsFundamentaMas from '../components/template/FundamentaMas/TabsFundamentaMas';
import NuestrosSliderProyectos from '../components/template/Proyectos/NuestrosSliderProyectos';
import SearchInicio from '../components/template/Inicio/SearchInicio';
import BannerInicio from '../components/organisms/Banner/BannerInicio';
import ReactModal from 'react-modal';
import TabsProyectos from '../components/template/Proyectos/TabsProyectos';

if (typeof window !== 'undefined') ReactModal.setAppElement('body');

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_Inicio {
            inicio {
              imagenModal {
                ...imagen
              }
              tipoPortada
              imagenDePortadaMobile {
                ...imagen
              }
              imagenDePortadaDesktop {
                ...imagen
              }
              slider {
                estilo4ImagenFondoDesktop {
                  ...imagen
                }
                estilo4ImagenFondoMobile {
                  ...imagen
                }
                estilo4ImagenPrincipalDesktop {
                  ...imagen
                }
                estilo4ImagenPrincipalMobile {
                  ...imagen
                }
                distrito
                estilo
                imagenFondo {
                  ...imagen
                }
                imagenFondoMovil {
                  ...imagen
                }
                logo {
                  ...imagen
                }
                metraje
                texto
                texto2
                url
                distritoMobile
              }

              tituloProyectosEnVenta
              tituloProyectosEntregados
              proyectosEntregados {
                ... on Wordpress_Proyecto {
                  databaseId
                  title
                  uri
                  featuredImage {
                    node {
                      ...imagen
                    }
                  }
                  distritos {
                    nodes {
                      slug
                      databaseId
                      name
                    }
                  }
                  template {
                    ... on Wordpress_Template_ProyectoEnVenta {
                      proyectoEnVenta {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                        lanzamientoDescripcion
                        precioDescripcion
                      }
                    }
                    ... on Wordpress_Template_ProximoProyecto {
                      proximoProyecto {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                      }
                    }
                  }
                  estados {
                    nodes {
                      name
                      slug
                    }
                  }
                  dormitorios {
                    nodes {
                      name
                    }
                  }
                }
              }
              proyectosEnVenta {
                ... on Wordpress_Proyecto {
                  databaseId
                  title
                  uri
                  featuredImage {
                    node {
                      ...imagen
                    }
                  }
                  distritos {
                    nodes {
                      slug
                      databaseId
                      name
                    }
                  }
                  template {
                    ... on Wordpress_Template_ProyectoEnVenta {
                      proyectoEnVenta {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                        lanzamientoDescripcion
                        precioDescripcion
                      }
                    }
                    ... on Wordpress_Template_ProximoProyecto {
                      proximoProyecto {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                      }
                    }
                  }
                  estados {
                    nodes {
                      name
                      slug
                    }
                  }
                  dormitorios {
                    nodes {
                      name
                    }
                  }
                }
              }

              tituloNuestraExperiencia
              detalleNuestraExperiencia {
                imagen {
                  ...imagen
                }
                numero
                texto
              }
            }
          }
        }
      }
    }
  }
`;

export default function Inicio({ data }) {
  const page = data?.wordpress?.page;
  const template = page?.template?.inicio;

  const tipoPortada = template?.tipoPortada;

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      {tipoPortada === 'slider' && (
        <SliderInicio data={template?.slider} titlePage={page.title} />
      )}
      {tipoPortada === 'image' && (
        <BannerInicio
          imageDesktop={template?.imagenDePortadaDesktop}
          imageMovile={template?.imagenDePortadaMobile}
        />
      )}
      <SearchInicio className="only-desk" titlePage={page.title} />

      <div className="only-desk">
        <TabsProyectos dataPage={template} />
      </div>
      <NuestrosSliderProyectos className="only-mov" titlePage={page.title} />

      <TabsFundamentaMas
        vermas={true}
        titlePage={page.title}
        isAnimado={true}
      />
      <NuestraInicio
        data={template?.detalleNuestraExperiencia}
        title={template?.tituloNuestraExperiencia}
        titlePage={page.title}
      />
    </LayoutGeneral>
  );
}
