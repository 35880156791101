import React from 'react';
import LayoutGeneral from '../components/organisms/Layout/LayoutGeneral';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby';
import GridBlogDetalle from '../components/template/Blog/GridBlogDetalle';
import BannerOthersPages from '../components/organisms/OthersPages/BannerOthersPages';
import UltimosPost from '../components/organisms/UltimosPost/UltimosPost';

export default function SinglePost({ data }) {
  let postDetalle = data.wordpress.post;
  const newUrl = `https://www.fundamenta.pe/blog${postDetalle.uri}`;
  postDetalle = {
    ...postDetalle,
    seo: {
      ...postDetalle.seo,
      opengraphUrl: newUrl,
      canonical: newUrl,
    },
    uri: `/blog${postDetalle.uri}`,
  };

  // console.log('post Detalle', postDetalle);

  return (
    <LayoutGeneral title={postDetalle?.title} slug={postDetalle?.uri}>
      <Seo post={postDetalle} />
      <h1 className="d-none">{postDetalle?.title}</h1>
      <BannerOthersPages
        imagen={postDetalle?.entrada.imagenPortada}
        imagenMovil={postDetalle?.entrada.imagenMovilPortada}
        typeStyle="banner-page"
      />
      <GridBlogDetalle post={postDetalle} />
      <UltimosPost postId={postDetalle.postId} />
    </LayoutGeneral>
  );
}

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    wordpress {
      post(id: $id, idType: DATABASE_ID) {
        seo {
          breadcrumbs {
            text
            url
          }
          canonical
          cornerstone
          focuskw
          fullHead
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
          schema {
            articleType
            pageType
            raw
          }
          schemaDetails
          title
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
            title
            uri
          }
        }
        title
        modified
        slug
        uri
        postId
        author {
          node {
            name
          }
        }
        categories {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        entrada {
          extracto
          textoCompleto
          imagenPortada {
            ...imagen
          }
          imagenMovilPortada {
            ...imagen
          }
          imagenListado {
            ...imagen
          }
        }
      }
    }
  }
`;
