import * as React from "react"
import { css } from "@emotion/react"
import Text from "../../atoms/Text/Text"
import SliderItemSomos from "../../moleculas/SlideItem/SliderItemSomos"

export default function ValoresSomos ({title, data, titlePage,...others}) {

  const contentCss = css`
    max-width: calc(100vw * (calc(1024 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(100vw * (calc(28 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(56 / var(--width_base))));
    color: white;
    
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(30 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));            
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(26 / var(--width_base))));
    line-height: calc(100vw * (calc(26 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(48 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
    
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;
  const valoresSectionCss = css`
  display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
  `;

  // const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  //   <button
  //     {...props}
  //     className={
  //       "slick-prev slick-arrow" +
  //       (currentSlide === 0 ? " slick-disabled" : "")
  //     }
  //     aria-hidden="true"
  //     aria-disabled={currentSlide === 0}
  //     type="button"
  //   >
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
  //       <polyline fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7px" points="35.86 3.5 4.95 34.41 34.51 63.97" />
  //     </svg>
  //   </button>
  // );
  //
  // const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  //   <button
  //     {...props}
  //     className={
  //       "slick-next slick-arrow" +
  //       (currentSlide === slideCount - 1 ? " slick-disabled" : "")
  //     }
  //     aria-hidden="true"
  //     aria-disabled={currentSlide === slideCount - 1}
  //     type="button"
  //   >
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
  //       <polyline fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7px" points="3.5 3.5 34.41 34.41 4.84 63.97" />
  //     </svg>
  //   </button>
  // );

  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 6000,
  //   pauseOnHover: false,
  //   pauseOnFocus: false,
  //   swipe: true,
  //   touchMove: true,
  //   prevArrow: <SlickArrowLeft/>,
  //   nextArrow: <SlickArrowRight/>,
  //   responsive: [
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         arrows: true,
  //       }
  //     }
  //   ]
  // }

  return(
    <section css={{backgroundColor: "#4A4A49"}} {...others}>
      {titlePage && <Text classEle="d-none" data={`Valores ${titlePage}`} typeEle="h2"/>}
      <div css={contentCss}>
        <Text typeEle="h2" data={title} styleCss={titleCss} align="center" data-aos="fade-right" data-aos-delay="200"/>
        {/* <SliderCarousel data={data} typeStyle="somos" settings={settings}/> */}
        <div css={valoresSectionCss}>
        {
        data &&
        data.map((dato, i)=>(
          <SliderItemSomos keyEle={i} data={dato}/>
        ))
          }
        </div>
      </div>
    </section>
  )
}