export default function getFecha(fecha, type = "") {
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Setiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]

  fecha = new Date(fecha)
  if (type === "normal") {
    return `
    ${fecha.getDay() <= 9 ? 0 : ""}${fecha.getDay()}/${
      fecha.getMonth() <= 9 ? 0 : ""
    }${fecha.getMonth()}/${fecha.getUTCFullYear()}`
  }
  if (type === "detalle") {
    const options = {
      timeZone: "America/Lima",
      day: "numeric",
      month: "long",
      year: "numeric",
    }
    return fecha.toLocaleDateString("es-PE", options)
  }
  return ""
}

