import * as React from 'react';
import { css } from '@emotion/react';
import Text from '../../atoms/Text/Text';
import GridProyecto from './GridProyecto';

export default function TabsProyectos({ dataPage, isProyecto, isAnimado }) {
  const title1 = dataPage?.tituloProyectosEnVenta;
  const title2 = dataPage?.tituloProyectosEntregados;
  const proyectosEnVenta = dataPage?.proyectosEnVenta;
  const proyectosEntregados = dataPage?.proyectosEntregados;

  // localStorage.setItem('distrito', selectDistrito?.current?.value);
  // const distrito = localStorage.getItem('distrito');

  const tabCss = css`
    width: 50%;
    background-color: #5e5e5e;
    padding-top: calc(100vw * (calc(24 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(24 / var(--width_base))));
    min-height: calc(100vw * (calc(103 / var(--width_base))));
    text-align: left;
    color: white;
    font-family: 'Montserrat Regular', sans-serif;

    p {
      line-height: calc(100vw * (calc(25 / var(--width_base))));
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      @media (max-width: 767px) {
        font-size: 0;
        line-height: 0;
      }

      @media (max-width: 767px) {
        line-height: calc(100vw * (calc(22 / var(--width_base))));
      }
    }

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      padding-top: calc(100vw * (calc(15 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }

    & strong {
      color: white;
      font-family: 'Montserrat Bold', sans-serif;
      font-size: calc(100vw * (calc(22 / var(--width_base))));

      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(18 / var(--width_base))));
      }
    }

    @media (max-width: 767px) {
      ${isProyecto && 'background-color: #5E5E5E'};
    }
    &.is-active {
      background-color: #444443;
      padding-top: calc(100vw * (calc(15 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(15 / var(--width_base))));
      @media (max-width: 767px) {
        padding-top: calc(100vw * (calc(0 / var(--width_base))));
        padding-bottom: calc(100vw * (calc(0 / var(--width_base))));

        ${isProyecto && 'background-color: #444443'};
      }

      & strong {
        color: #93ba1f;
      }

      > span {
        transform: scale(1.1);
      }
    }
    & * {
      pointer-events: none;
    }

    span {
      transition: transform 0.3s;
      display: inline-block;
      transform-origin: left;
    }

    &:hover {
      padding-top: calc(100vw * (calc(15 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(15 / var(--width_base))));
      @media (max-width: 767px) {
        padding-top: calc(100vw * (calc(0 / var(--width_base))));
        padding-bottom: calc(100vw * (calc(0 / var(--width_base))));
      }

      > span {
        transform: scale(1.1);
      }
    }
    &:hover .tab-image-2 img {
      width: calc(100vw * (calc(120 / var(--width_base))));
      height: auto;
      max-width: fit-content;
      @media (max-width: 767px) {
        pointer-events: none;
      }
    }
    &.is-active .tab-image-2,
    & .tab-image-1 {
      display: none !important;
    }
    &.is-active .tab-image-1 {
      display: block !important;
    }
    &.is-active .tab-image-1 img {
      width: calc(100vw * (calc(120 / var(--width_base))));
      height: auto;
      max-width: fit-content;
      @media (max-width: 767px) {
        width: calc(100vw * (calc(120 / var(--width_base))));
        height: calc(100vw * (calc(55 / var(--width_base))));
        max-width: calc(100vw * (calc(83 / var(--width_base))));
      }
    }
    &:nth-of-type(1) {
      padding-left: calc(100vw * (calc(165 / var(--width_base))));
      @media (max-width: 767px) {
        padding-left: calc(100vw * (calc(20 / var(--width_base))));
        padding-right: calc(100vw * (calc(20 / var(--width_base))));
      }
    }
    &:nth-of-type(2) {
      padding-left: calc(100vw * (calc(60 / var(--width_base))));
      @media (max-width: 767px) {
        padding-left: calc(100vw * (calc(20 / var(--width_base))));
        padding-right: calc(100vw * (calc(20 / var(--width_base))));
      }
    }
  `;

  const tabList = css`
    display: flex;
  `;

  const tabPannel = css`
    display: none;
    padding-top: calc(100vw * (calc(117 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(110 / var(--width_base))));
    padding-left: calc(100vw * (calc(100 / var(--width_base))));
    padding-right: calc(100vw * (calc(100 / var(--width_base))));
    background-color: #444443;
    min-height: calc(100vw * (calc(288 / var(--width_base))));
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(30 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(16 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
      ${isProyecto && 'background-color: #444443'}
    }

    &.is-active {
      display: block;
    }
  `;

  const contentCss = css`
    background-color: #5e5e5e;
    text-align: center;
    padding-top: calc(100vw * (calc(51 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(55 / var(--width_base))));
    font-family: 'Montserrat Regular', sans-serif;
    @media (max-width: 767px) {
      padding-bottom: calc(100vw * (calc(2 / var(--width_base))));
      ${isProyecto && 'background-color: #444443'}
    }
  `;

  // SCRIPT
  const handleTab = (e) => {
    const tabsButton = document.querySelectorAll('.tabs2-button');
    tabsButton.forEach(
      (ele) =>
        ele.classList.contains('is-active') && ele.classList.remove('is-active')
    );
    let tabsPanel = document.querySelectorAll('.tabs2-panel');
    tabsPanel.forEach(
      (ele) =>
        ele.classList.contains('is-active') && ele.classList.remove('is-active')
    );
    e.target.classList.add('is-active');
    document.getElementById(e.target.dataset.id).classList.add('is-active');
  };

  return (
    <section css={contentCss}>
      <Text
        classEle="d-none"
        data={`Proyectos en venta y entregados`}
        typeEle="h2"
      />
      <div>
        <div css={tabList}>
          <button
            css={tabCss}
            data-id="tabs2-panel-1"
            onClick={handleTab}
            className="tabs2-button is-active"
            data-aos={isAnimado && 'fade-right'}
            data-aos-delay="200"
          >
            <span dangerouslySetInnerHTML={{ __html: title1 }} />
          </button>
          <button
            css={tabCss}
            data-id="tabs2-panel-2"
            onClick={handleTab}
            className="tabs2-button"
            data-aos={isAnimado && 'fade-left'}
            data-aos-delay="200"
          >
            <span dangerouslySetInnerHTML={{ __html: title2 }} />
          </button>
        </div>
        <div
          css={tabPannel}
          id="tabs2-panel-1"
          className="tabs2-panel is-active"
        >
          <div>
            {proyectosEnVenta && (
              <GridProyecto dataProyectos={proyectosEnVenta} />
            )}
          </div>
        </div>
        <div css={tabPannel} className="tabs2-panel" id="tabs2-panel-2">
          <div>
            {proyectosEntregados && (
              <GridProyecto
                dataProyectos={proyectosEntregados}
                isProjectsDelivered={true}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
