import React from 'react';
import { Link } from 'gatsby'
import { css } from "@emotion/react"
import Icon from "../../atoms/Icon/Icon"

export default function Pagination({ pageNumber, hasNextPage, total, itemsPerPage, postsPath, paginationPrefix}) {

  const isLast = pageNumber === total / itemsPerPage

  const paginationNumbersListCss = css`
    display: flex;
    flex-wrap: wrap;
  `;

  const paginacionCss = css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px){
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const paginationLinkCss = css`
    padding-right: calc(100vw * (calc(9 / var(--width_base))));
    padding-left: calc(100vw * (calc(9 / var(--width_base))));
    margin-left: calc(100vw * (calc(2 / var(--width_base))));
    margin-right: calc(100vw * (calc(2 / var(--width_base))));
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    color: white;
    height: calc(100vw * (calc(35 / var(--width_base))));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    min-width: calc(100vw * (calc(30 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(5 / var(--width_base))));
    @media (max-width: 767px){
      margin-bottom: calc(100vw * (calc(3 / var(--width_base))));
    }
  `;

  const paginationLinkNumberCss = css`
    ${paginationLinkCss};
    background-color: #93BA1F;
  `;

  const paginationLinkControlCss = css`
    ${paginationLinkCss};
    background-color: #93BA1F;
  `;

  return (
    <div css={paginacionCss}>
      {pageNumber > 1 && (
        <>
          {pageNumber !== 1 ? (
            <Link
              css={paginationLinkControlCss}
              to={
                pageNumber > 2
                  ? `${postsPath}/${paginationPrefix}/${pageNumber - 1}`
                  : `/blog`
              }
            >
              <Icon type="arrow-left"/> Página anterior
            </Link>
          ) : (
            <>
              <div sx={{ color: 'muted' }}><Icon type="arrow-left"/> Página anterior</div>
            </>
          )}
        </>
      )}
      <nav role="navigation" aria-label="Pagination Navigation">
        <ul css={paginationNumbersListCss}>
          {Array.from({ length: total / itemsPerPage }, (_, i) => (
            <li>
              <Link
                css={paginationLinkNumberCss}
                key={`pagination-number${i + 1}`}
                to={
                  i === 0
                    ? `${postsPath}/`
                    : `${postsPath}/${paginationPrefix}/${i + 1}`
                }
              >
                {i + 1}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      {hasNextPage && (
        <>
          {!isLast ? (
            <Link
              css={paginationLinkControlCss}
              to={`${postsPath}/${paginationPrefix}/${pageNumber + 1}`}>
              Página siguiente <Icon type="arrow-right"/>
            </Link>
          ) : (
            <div sx={{ color: 'muted' }}> Página siguiente <Icon type="arrow-right"/></div>
          )}
        </>
      )}
    </div>
  );

}