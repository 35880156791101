import React from 'react';
import { graphql, Link, useStaticQuery } from "gatsby"
import { css } from "@emotion/react"
import Text from "../../atoms/Text/Text"
import EntryCard from "../../moleculas/EntryCard/EntryCard"

export default function UltimosPost({postId}) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        posts(first: 4) {
          nodes {
            postId
            title
            slug
            modified
            categories {
              nodes {
                name
              }
            }
            author {
              node {
                name
              }
            }
            entrada {
              extracto
              textoCompleto
              imagenPortada {
                ...imagen
              }
              imagenMovilPortada {
                ...imagen
              }
              imagenListado {
                ...imagen
              }
            }
          }
        }
      }
    }
  `)

  const lists = data?.wordpress?.posts?.nodes

  var borrable = false;
  var posicion = false;
  for (let i = 0; i < lists.length; i++) {
    const element = lists[i];
    if (element.postId === postId) {
      borrable = true;
      posicion = i;
    }
  }

  if (borrable) {
    lists.splice(posicion, 1);
  } else {
    lists.pop();
  }

  const titleCss = css`
    font-weight: bold;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    font-size: calc(100vw * (calc(28 / var(--width_base))));
    line-height: calc(100vw * (calc(28 / var(--width_base))));
    color: white;
    font-family: "Montserrat Regular", sans-serif;
    width: 100%;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      line-height: calc(100vw * (calc(24 / var(--width_base))));
      text-align:center;
    }
  `
  const sectionCss = css`
    background-color: #5e5e5e;
    padding-top: calc(100vw * (calc(45 / var(--width_base))));
    padding-right: calc(100vw * (calc(123 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
    padding-left: calc(100vw * (calc(123 / var(--width_base))));
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 767px) {
  padding-right: 0;
  padding-left: 0;
    }
  `
  const cardCss = css`
    background-color: #5e5e5e;
    width: calc(100vw * (calc(427 / var(--width_base))));
  `
  return (
    <div css={sectionCss}>
       <Text data="También te puede interesar:" styleCss={titleCss} />
       {lists &&
         lists.map((item, i) => (
           <div css={cardCss} key={i}>
             <Link to={`/blog/${item?.slug}`}>
               <EntryCard data={item} />
             </Link>
           </div>
         ))}
     </div>
  )
}
