const convertStringAObj = (data) =>{

  if ((data === null) || ( data==='')){
    return ""
  }else{
    data = data.toString();
    data = data.replace( /(\r\n)/ig, ',');
    return data = data.split(",");
  }

}

export default convertStringAObj;