import * as React from 'react';
import { Fragment } from 'react';
import { css } from '@emotion/react';
import CardProyecto2 from '../../organisms/Proyectos/CardProyecto2';
import CardProyecto from '../../organisms/Proyectos/CardProyecto';

export default function GridProyecto({
  dataProyectos,
  isProjectsDelivered = false,
}) {
  const proyectosMostrar = dataProyectos;

  const styleEleCss = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 768px) {
    }
  `;

  const sliderItemCss = css`
    // padding-left: calc(100vw * (calc(7 / var(--width_base))));
    // padding-right: calc(100vw * (calc(7 / var(--width_base))));

    width: 18%;

    @media (max-width: 768px) {
      width: initial;
    }
  `;

  return (
    <div css={styleEleCss}>
      {proyectosMostrar &&
        proyectosMostrar.map((proyecto, i) => (
          <Fragment key={i}>
            <CardProyecto
              isProjectsDelivered={isProjectsDelivered}
              data={proyecto}
              styleCss={sliderItemCss}
            />
          </Fragment>
        ))}
    </div>
  );
}
