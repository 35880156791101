import * as React from "react"
import CardProyecto from "./CardProyecto"
import { css } from "@emotion/react"
import { parserString } from "../../../helper/parserString";

export default function GrillasItemNormalProyectos ({dataProyectos, dataProximosProyectos}) {

  const columnCss = css`
    padding-left: calc(100vw * (calc(20 / var(--width_base))));
    padding-right: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      padding-left: initial;
      padding-right: initial;
      margin-bottom: calc(100vw * (calc(35 / var(--width_base))));
    }
  `;

  const contentCss = css`
    max-width: calc(100vw * (calc(1160 / var(--width_base))));
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `;

  return(
    <div className="o-hidden mx-auto" css={contentCss}>
      {
        dataProyectos && dataProyectos.map((proyecto, i)=>(
          <div key={i} className={`column column-33 project-item ${parserString(proyecto?.distritos?.nodes[0]?.name)}`} css={columnCss}>
            <CardProyecto data={proyecto}/>
          </div>
        ))
      }
      {
        dataProximosProyectos && dataProximosProyectos.map((proyecto, i)=>(
          <div key={i} className="column column-33" css={columnCss}>
            <CardProyecto data={proyecto} isProyecto={false}/>
          </div>
        ))
      }
    </div>
  )
}
