import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import BannerOthersPages from "../components/organisms/OthersPages/BannerOthersPages"
import NuestrosBeneficios from "../components/template/Beneficios/NuestrosBeneficios"
import BestPlaceBeneficios from "../components/template/Beneficios/BestPlaceBeneficios"
import PromocionesBeneficios from "../components/template/Beneficios/PromocionesBeneficios"
import FormularioBeneficios from "../components/template/Beneficios/FormularioBeneficios"
import ProgramaBeneficios from "../components/template/Beneficios/ProgramaBeneficios"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        template {
          ... on Wordpress_Template_Beneficios {
            templateName
            beneficios {
              cardsPrograma {
                imagen {
                  ...imagen
                }
              }
              cardsPromociones {
                imagen {
                  ...imagen
                }
              }
              certificadosNuestros {
                imagen {
                  ...imagen
                }
                texto
              }
              imagenBestPlaceMovilPromociones {
                ...imagen
              }
              imagenBestPlacePromociones {
                ...imagen
              }
              imagenMovilPortada {
                ...imagen
              }
              imagenPortada {
                ...imagen
              }
              textoNuestros
              textoPortada
              textoPromociones
              tituloPrograma
              tituloPromociones
            }
          }
        }

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function Beneficios({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.beneficios

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <BannerOthersPages
        title={template?.textoPortada}
        imagen={template?.imagenPortada}
        imagenMovil={template?.imagenMovilPortada}
        typeStyle="banner-page"
        titlePage={page.title}
      />
      <ProgramaBeneficios
        title={template?.tituloPrograma}
        data={template?.cardsPrograma}
        titlePage={page.title}
      />
      <FormularioBeneficios titlePage={page.title} />
      <PromocionesBeneficios
        title={template?.tituloPromociones}
        data={template?.cardsPromociones}
        titlePage={page.title}
      />
      <BestPlaceBeneficios
        imagen={template?.imagenBestPlacePromociones}
        imagenMovil={template?.imagenBestPlaceMovilPromociones}
        texto={template?.textoPromociones}
        titlePage={page.title}
      />
      <NuestrosBeneficios
        title={template?.textoNuestros}
        data={template?.certificadosNuestros}
        titlePage={page.title}
      />
    </LayoutGeneral>
  )
}

