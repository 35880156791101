import * as React from "react"
import Text from "../../atoms/Text/Text"
import { css } from "@emotion/react"
import ContactoForm from "../../organisms/Form/ContactoForm"

export default function FormContacto ({title, titlePage}) {

  const sectionCss = css`
    background-color: #4A4A49;
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
  `;
  const contentCss = css`
    background-color: #4A4A49;
    max-width: calc(100vw * (calc(835 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    padding: calc(100vw * (calc(20 / var(--width_base))));
    margin-top: calc(100vw * (calc(-30 / var(--width_base))));
    z-index: 1;
    position: relative;
    @media (max-width: 767px) {
      //padding: calc(100vw * (calc(15 / var(--width_base)))) !important;
      max-width: initial;
      margin-left: calc(100vw * (calc(15 / var(--width_base))));
      margin-right: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  return(
    <section css={sectionCss}>
      {titlePage && <Text classEle="d-none" data={`Formulario ${titlePage}`} typeEle="h2"/>}
      <div css={contentCss}>
        <Text data={title} styleCss={titleCss} colorText="white" align="center" data-aos="fade-right" data-aos-delay="200"/>
        <ContactoForm/>
      </div>
    </section>
  )
}
