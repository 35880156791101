import * as React from "react"
import { css } from "@emotion/react"

export default function Iframe ({styleCss, url, ...others}) {

  const iframeCss = css`
    width: 100%;
    height: calc(100vw * (calc(600 / var(--width_base))));
    ${styleCss};
    @media (max-width: 767px) {
      height: calc(100vw * (calc(241 / var(--width_base))));          
    }
  `;

  return(
    <iframe
      title="Iframe Proyecto"
      css={iframeCss}
      with="100%"
      height="100%"
      src={url}
      allow="encrypted-media"
      allowFullScreen=""
      {...others}
    />
  )
}
