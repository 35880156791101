import * as React from "react"
import Text from "../../atoms/Text/Text"
import { css } from "@emotion/react"
import SliderProyectos from "../Proyectos/SliderProyectos"

export default function OtrosProyecto ({idProyecto, titlePage, ...others}) {

  const sectionCss = css`
    background-color: #5E5E5E;
    padding-top: calc(100vw * (calc(67 / var(--width_base))));
    overflow: hidden;
  `;

  const titleCss = css`
    line-height: calc(100vw * (calc(24 / var(--width_base))));
    font-size: calc(100vw * (calc(24 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(65 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
  `;

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7px" points="35.86 3.5 4.95 34.41 34.51 63.97" />
      </svg>
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7px" points="3.5 3.5 34.41 34.41 4.84 63.97" />
      </svg>
    </button>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: true,
    touchMove: true,
    prevArrow: <SlickArrowLeft/>,
    nextArrow: <SlickArrowRight/>,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  }
  
  const contentCss = css`
    max-width: calc(100vw * (calc(948 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
  `;

  const sliderCss = css`
    & .slick-arrow, & .slick-arrow svg{
      width: calc(100vw * (calc(9.9 / var(--width_base))));
      height: calc(100vw * (calc(22.3 / var(--width_base))));
      top: 40%;
    }
    
    & polyline{
      stroke: #93BA1F;
    }
    
    & .slick-next{
      right: calc(100vw * (calc(-25 / var(--width_base))));
    }

    & .slick-prev{
      left: calc(100vw * (calc(-25 / var(--width_base))));
    }
    
  `;
  
  return(
    <section css={sectionCss} {...others}>
      {titlePage && <Text classEle="d-none" data={`Otros Proyectos ${titlePage}`} typeEle="h2"/>}
      <Text data="OTROS PROYECTOS QUE TE PUEDEN INTERESAR" align="center" colorText="white" styleCss={titleCss} data-aos="fade-right" data-aos-delay="200"/>
      <div css={contentCss}>
        <SliderProyectos idProyecto={idProyecto} settings={settings} styleEleCss={sliderCss}/>
      </div>
    </section>
  )
}
