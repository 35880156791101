import * as React from "react"
import SlideItem from "../../moleculas/SlideItem/SlideItem"
import Text from "../../atoms/Text/Text"

export default function BannerOthersPages ({title, subtitle, imagen, typeStyle, imagenMovil, titlePage}) {

  var datos = {};
  datos.texto = title;
  datos.subtitle = subtitle;
  datos.imagen = imagen;
  datos.imagenMovil = imagenMovil;

  return(
    <section>
      {titlePage && <Text classEle="d-none" data={`Banner ${titlePage}`} typeEle="h2"/>}
      <SlideItem data={datos} typeStyle={typeStyle}/>
    </section>
  )
}

BannerOthersPages.defaultProps = {
  typeStyle: "banner-page"
}
