import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import Image from "../components/atoms/Image/Image"
import Text from "../components/atoms/Text/Text"
import { css } from "@emotion/react"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        template {
          ... on Wordpress_Template_Gracias {
            templateName
            gracias {
              imagenPortada {
                ...imagen
              }
              textoPortada
            }
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function Gracias({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.gracias

  const contentCss = css`
    position: absolute;
    bottom: calc(100vw * (calc(20 / var(--width_base))));
    left: 0;
    right: 0;
    max-width: calc(100vw * (calc(835 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    @media (max-width: 767px) {
      max-width: initial;
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      bottom: calc(100vw * (calc(40 / var(--width_base))));
    }
  `

  const titleCss = css`
    font-size: calc(100vw * (calc(35 / var(--width_base))));
    line-height: calc(100vw * (calc(43 / var(--width_base))));
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(25 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const textCss = css`
    font-family: "Montserrat Bold", sans-serif;
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
    }
    & br {
      @media (max-width: 767px) {
        display: none;
      }
    }
  `

  const imageCss = css`
    width: 100%;
    height: calc(100vh - calc(100vw * (calc(100 / var(--width_base)))));
    min-height: calc(100vw * (calc(500 / var(--width_base))));
    @media (max-width: 767px) {
      height: calc(100vh - calc(100vw * (calc(65 / var(--width_base)))));
    }
  `

  const imageContentCss = css`
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: calc(100vw * (calc(207 / var(--width_base))));
      opacity: 0.8;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: -moz-linear-gradient(
        50% -15.24% -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-gradient(
        linear,
        50% -15.24%,
        50% 104.78%,
        color-stop(0, rgba(255, 255, 255, 0)),
        color-stop(1, rgba(0, 0, 0, 1))
      );
      background: -o-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -ms-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#000000' ,GradientType=0)";
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=0);
    }
  `

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <section
        css={css`
          position: relative;
        `}
      >
        <div css={imageContentCss}>
          <Image
            imagen={template?.imagenPortada}
            styleCss={imageCss}
            objectFit="cover"
            position="center top"
          />
        </div>
        <div css={contentCss}>
          <Text
            styleCss={titleCss}
            data={page?.title}
            colorText="white"
            data-aos="fade-right"
            data-aos-delay="200"
          />
          <Text
            styleCss={textCss}
            data={template?.textoPortada}
            colorText="#8cb036"
            data-aos="fade-right"
            data-aos-delay="300"
          />
        </div>
      </section>
    </LayoutGeneral>
  )
}

