import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import BannerOthersPages from "../components/organisms/OthersPages/BannerOthersPages"
import FormContacto from "../components/template/Contacto/FormContacto"
import { css } from "@emotion/react"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri

        template {
          ... on Wordpress_Template_Contacto {
            templateName
            contacto {
              tituloFormulario
              textoPortada
              imagenPortada {
                ...imagen
              }
              imagenMovilPortada {
                ...imagen
              }
            }
          }
        }

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

const spaceCss = css`
  height: calc(100vw * (calc(40 / var(--width_base))));
  @media (max-width: 1366px) {
    height: calc(100vw * (calc(70 / var(--width_base))));
  }

  @media (max-width: 767px) {
    height: calc(100vw * (calc(40 / var(--width_base))));
  }
`

export default function Contacto({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.contacto

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <div css={spaceCss}></div>
      <BannerOthersPages
        title=""
        imagen={template?.imagenPortada}
        typeStyle="normal"
        titlePage={page.title}
      />
      <FormContacto title={template?.tituloFormulario} titlePage={page.title} />
    </LayoutGeneral>
  )
}

