import React, { useEffect } from "react";

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";
import "../../_settings/Components/FancyboxModal.css";

function Fancybox({delegateEle, optionsEle, children}) {
  const delegate = delegateEle || "[data-fancybox]";

  useEffect(() => {
    const opts = optionsEle || {};

    NativeFancybox.bind(delegate, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, [optionsEle, delegate]);

  return <>{children}</>;
}

export default Fancybox;