import * as React from 'react';
import Text from '../../atoms/Text/Text';
import { css } from '@emotion/react';
import CardVentaProyecto from './CardVentaProyecto';
import Icon from '../../atoms/Icon/Icon';

export default function SalaVentasProyecto({
  title,
  horario,
  dias,
  data,
  direccion,
  titlePage,
}) {
  const sectionCss = css`
    background-color: #444443;
    padding-top: calc(100vw * (calc(86 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(49 / var(--width_base))));
    @media (max-width: 767px) {
      background-color: #444443;
      padding-left: calc(100vw * (calc(30 / var(--width_base))));
      padding-right: calc(100vw * (calc(30 / var(--width_base))));
      padding-top: calc(100vw * (calc(40 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

  const contentCss = css`
    max-width: calc(100vw * (calc(840 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(43 / var(--width_base))));
    font-family: 'Montserrat Bold', sans-serif;
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
      color: #999;
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
      max-width: calc(100vw * (calc(120 / var(--width_base))));
      margin-left: auto;
      margin-right: auto;
    }
  `;

  const detalleItemCss = css`
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin-bottom: calc(100vw * (calc(23 / var(--width_base))));
    }

    &:first-of-type {
      margin-top: calc(100vw * (calc(43 / var(--width_base))));
      @media (max-width: 767px) {
        margin-top: initial;
      }
    }
  `;

  const detalleItemRightCss = css`
    display: flex;
    margin-left: calc(100vw * (calc(44 / var(--width_base))));
    flex-direction: column;
    @media (max-width: 767px) {
      margin-left: calc(100vw * (calc(12 / var(--width_base))));
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    &:not(:last-of-type) {
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
      color: white;
    }
  `;

  const cardsVentas = css`
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

  return (
    <section css={sectionCss}>
      {titlePage && (
        <Text
          classEle="d-none"
          data={`Sala de ventas ${titlePage}`}
          typeEle="h2"
        />
      )}
      <Text
        data={
          title
            ? title
            : `<p>COTIZADOR DE <span style="color: #93ba1f;">DEPARTAMENTOS</span></p>`
        }
        colorText="white"
        styleCss={titleCss}
        align="center"
        data-aos="fade-right"
        data-aos-delay="200"
      />
      <div css={contentCss}>
        <div>
          <div css={detalleItemCss} data-aos="fade-right" data-aos-delay="200">
            <Icon typeEle="svg-horario" />
            <div css={detalleItemRightCss}>
              <Text
                data="Horario de Atención"
                colorText="white"
                styleCss={textCss}
              />
              <Text data={horario} colorText="white" styleCss={textCss} />
            </div>
          </div>
          <div css={detalleItemCss} data-aos="fade-right" data-aos-delat="300">
            <Icon typeEle="svg-calendario" />
            <div css={detalleItemRightCss}>
              <Text
                data="Días de Atención"
                colorText="white"
                styleCss={textCss}
              />
              <Text data={dias} colorText="white" styleCss={textCss} />
            </div>
          </div>
          <div css={detalleItemCss} data-aos="fade-right" data-aos-delat="400">
            <Icon typeEle="svg-mapa" />
            <div css={detalleItemRightCss}>
              <Text data="Dirección" colorText="white" styleCss={textCss} />
              <Text data={direccion} colorText="#779B3E" styleCss={textCss} />
            </div>
          </div>
        </div>
        <div css={cardsVentas} data-aos="fade-left" data-aos-delat="200">
          {data &&
            data.map((dato, i) => <CardVentaProyecto keyEle={i} data={dato} />)}
        </div>
      </div>
    </section>
  );
}
