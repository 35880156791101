import * as React from "react"
import { css } from "@emotion/react";
import Image from "../../atoms/Image/Image";

export default function BannerInicio ({imageDesktop, imageMovile}) {

    const imgDesk = css`
        width: 100%;
        height: 97vh;
        object-position: left center !important;

        @media (max-width: 767px) {
            object-position: left center !important;
            height: 90vh;
        }
    `;

    return (
        <div>
            {
                imageDesktop && 
                <Image imagen={imageDesktop} objectFit="cover" styleCss={imgDesk} classEle="only-desk"/>
            }
            {
                imageMovile && 
                <Image imagen={imageMovile} objectFit="cover" styleCss={imgDesk} classEle="only-mov"/>
            }
        </div>
    )
}