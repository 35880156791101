import * as React from "react"
import { useStaticQuery, graphql} from "gatsby"
import { css } from "@emotion/react"
import Button from "../../atoms/Button/Button"
import { useCallback, useEffect, useState } from "react"

export default function Search (props) {

  const {
    selectDistrito,
    selectEstado,
    selectProyecto,
    eventEle
  } = props;

  const data = useStaticQuery(graphql`
    {
      wordpress {
        distritos(where: {exclude: 83}) {
          nodes {
            name
            databaseId
          }
        }
        estados {
          nodes {
            name
          }
        }
        proyectos {
          nodes {
            title
          }
        }
        proximosProyectos {
          nodes {
            title
          }
        }    
      }
    }
  `);

  const distritos = data?.wordpress?.distritos.nodes;
  const proyectos = data?.wordpress?.proyectos.nodes;
  const proximosProyectos = data?.wordpress?.proximosProyectos.nodes;
  const estados = data?.wordpress?.estados.nodes;

  const [valueDistrito, setValueDistrito] = useState("");
  const [valueEstado, setValueEstado] = useState("");
  const [valueProyecto, setValueProyecto] = useState("");

  const valoresPredeterminados = useCallback(() => {
    const dataDistrito = localStorage.getItem("distrito");
    const dataEstado = localStorage.getItem("estado");
    const dataProyecto = localStorage.getItem("proyecto");
    setValueDistrito(dataDistrito);
    setValueEstado(dataEstado);
    setValueProyecto(dataProyecto);
    },[]
  );


  useEffect(() => {
    valoresPredeterminados();
  }, [valoresPredeterminados])


  const contentCss = css`
    max-width: calc(100vw * (calc(835 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-top: calc(100vw * (calc(45 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
    
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      padding-top: calc(100vw * (calc(25 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(25 / var(--width_base))));
    }
  `;

  const searchCss = css`
    background-color: #4A4A49;
  `;

  const controlCss = css`
    height: calc(100vw * (calc(28 / var(--width_base))));
    display: flex;
    align-items: center;
    &:not(:last-of-type){
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(7 / var(--width_base))));          
      }
    }
  `;

  const selectCss = css`
    border-radius: 0;
    font-size: calc(100vw * (calc(11 / var(--width_base))));
    line-height: calc(100vw * (calc(11 / var(--width_base))));
    margin-right: calc(100vw * (calc(9 / var(--width_base))));
    border: calc(100vw * (calc(1 / var(--width_base)))) solid #B1B1B1;
    padding-left: calc(100vw * (calc(14 / var(--width_base))));
    padding-right: calc(100vw * (calc(14 / var(--width_base))));
    font-family: "Montserrat Medium", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
      background-color: #c1bfbf;
    }
    ${controlCss};
  `;

  const btnCss = css`
    ${controlCss};
    background-color: #93BA1F;
    color: white;
    width: calc(100vw * (calc(198 / var(--width_base))));
    font-size: calc(100vw * (calc(11 / var(--width_base))));
    line-height: calc(100vw * (calc(11 / var(--width_base))));
    flex-shrink: 0;
    justify-content: center;
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(25 / var(--width_base))));
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
      padding-top: calc(100vw * (calc(18 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(18 / var(--width_base))));
    }
  `;

  return(
    <div css={searchCss}>
      <div css={contentCss}>
        <select name="distrito" css={selectCss} ref={selectDistrito} value={valueDistrito} aria-label="select district">
          <option value="">DISTRITO</option>
          {
            distritos && distritos.map((distrito, i)=>(
              <option value={distrito.name.toLowerCase()}>{distrito.name.toUpperCase()}</option>
            ))
          }
        </select>
        <Button typeEle="button" styleEle={btnCss} eventEle={eventEle}>BUSCAR PROYECTO</Button>
      </div>
    </div>
  )
}

Search.defaultProps = {
  valueDistrito: "",
  valueEstado: "",
  valueProyecto: "",
}
