import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

export default function Button({
  link,
  typeEle,
  styleTypeEle,
  children,
  styleEle,
  eventEle,
  disabled,
  classEle,
  ...others
}) {
  let styleButtonCss = {}

  const alinearCss = css`
    display: inline-flex;
    align-items: center;
    align-content: center;
  `

  switch (styleTypeEle) {
    case "ver-mas":
      styleButtonCss = css`
        color: white;
        background-color: #93ba1f;
        font-size: calc(100vw * (calc(13 / var(--width_base))));
        line-height: calc(100vw * (calc(13 / var(--width_base))));
        padding: calc(100vw * (calc(5 / var(--width_base))))
          calc(100vw * (calc(18 / var(--width_base))));
        @media (max-width: 767px) {
          font-size: calc(100vw * (calc(14 / var(--width_base))));
          line-height: calc(100vw * (calc(14 / var(--width_base))));
          text-transform: uppercase;
          padding: calc(100vw * (calc(9 / var(--width_base))))
            calc(100vw * (calc(18 / var(--width_base))));
        }
        ${styleEle};
        ${alinearCss};
      `
      break
    case "submit":
      styleButtonCss = css`
        color: white;
        background-color: #93ba1f;
        font-size: calc(100vw * (calc(18 / var(--width_base))));
        line-height: calc(100vw * (calc(18 / var(--width_base))));
        min-width: calc(100vw * (calc(190 / var(--width_base))));
        min-height: calc(100vw * (calc(52 / var(--width_base))));
        margin-top: calc(100vw * (calc(69 / var(--width_base))));
        justify-content: center;
        @media (max-width: 767px) {
          font-size: calc(100vw * (calc(14 / var(--width_base))));
          line-height: calc(100vw * (calc(14 / var(--width_base))));
          min-height: calc(100vw * (calc(42 / var(--width_base))));
          min-width: calc(100vw * (calc(150 / var(--width_base))));
          margin-top: calc(100vw * (calc(30 / var(--width_base))));
        }
        ${styleEle};
        ${alinearCss};
      `
      break
    case "pdf":
      styleButtonCss = css`
        color: white;
        background-color: #93ba1f;
        font-size: calc(100vw * (calc(15 / var(--width_base))));
        font-family: "Montserrat Regular  ", sans-serif;
        padding: calc(100vw * (calc(16 / var(--width_base))))
          calc(100vw * (calc(45 / var(--width_base))));
        max-width: calc(100vw * (calc(212 / var(--width_base))));
        text-transform: uppercase;
        // &:not(:last-of-type){
        //   margin-right: calc(100vw * (calc(15 / var(--width_base))));
        // }
        ${styleEle};
        ${alinearCss};
        @media (max-width: 767px) {
          padding: calc(100vw * (calc(13 / var(--width_base))))
            calc(100vw * (calc(35 / var(--width_base))));
          max-width: calc(100vw * (calc(280 / var(--width_base))));
          font-size: calc(100vw * (calc(12 / var(--width_base))));
        }
      `
      break
    default:
      styleButtonCss = css`
        ${styleEle};
        ${alinearCss};
      `
      break
  }

  const handleClickCotizar = e => {
    e.preventDefault()
    if (document.querySelector("#cotizar")) {
      let heightMenu = 0
      if (window.screen.width > 767) heightMenu = 120
      if (window.screen.width > 767 && window.screen.width <= 1440)
        heightMenu = 100
      if (window.screen.width <= 767) heightMenu = 95
      const offsetTop =
        document.querySelector("#cotizar").offsetTop - heightMenu
      window.scroll({
        top: offsetTop,
        behavior: "smooth",
      })
    }
  }

  return (
    <>
      {typeEle === "pdf" && link && (
        <a
          target="_blank"
          className={classEle}
          href={link}
          css={styleButtonCss}
          rel="noreferrer noopener"
          {...others}
        >
          {children}
        </a>
      )}
      {typeEle === "link" && link && (
        <Link className={classEle} to={link} css={styleButtonCss} {...others}>
          {children}
        </Link>
      )}
      {typeEle === "linkTarget" && link && (
        <a
          className={classEle}
          href={link}
          target="_blank"
          rel="noreferrer noopener"
          css={styleButtonCss}
          {...others}
        >
          {children}
        </a>
      )}
      {typeEle === "button" && (
        <button
          className={classEle}
          type="button"
          onClick={eventEle}
          css={styleButtonCss}
          {...others}
        >
          {children}
        </button>
      )}
      {typeEle === "cotizar" && (
        <button
          className={classEle}
          type="button"
          onClick={handleClickCotizar}
          css={styleButtonCss}
          {...others}
        >
          {children}
        </button>
      )}
      {typeEle === "submit" && (
        <button
          className={classEle}
          type="submit"
          onClick={eventEle}
          css={styleButtonCss}
          disabled={disabled}
          {...others}
        >
          {children}
        </button>
      )}
    </>
  )
}

