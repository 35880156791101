import * as React from "react"
import { useState } from "react"
import Text from "../../atoms/Text/Text"
import SearchBlog from "../../moleculas/Search/SearchBlog"

export default function BarSearchBlog ({ titlePage, setData, data}) {

  const [valueCategoria, setValueCategoria] = useState("");

  const handleSelectCategoria = (e) =>{
    setValueCategoria(e.target.value);
  }

  const handleSearch = () =>{
    let newBlogs = [...data];
    if(valueCategoria !== ""){
      newBlogs = newBlogs.filter((blogM) => blogM?.categories.nodes.some(blogCat => blogCat.name === valueCategoria) );
      setData(newBlogs);
    }

  }

  return(
    <section>
      {titlePage && <Text classEle="d-none" data={`Banner ${titlePage}`} typeEle="h2"/>}
      <SearchBlog
        valueCategoria={valueCategoria}
        eventSelect={handleSelectCategoria}
        eventEle={handleSearch}
      />
    </section>
  )
}

// import * as React from "react"
// import Search from "../../moleculas/Search/Search"
// import { useRef } from "react"
// import Text from "../../atoms/Text/Text"
//
// export default function SearchProyecto ({ titlePage,...others}) {
//
//   const selectDistrito = useRef(null);
//   const selectEstado = useRef(null);
//   const selectProyecto = useRef(null);
//
//   const handleSearch = () =>{
//     localStorage.setItem("distrito",selectDistrito?.current?.value);
//     localStorage.setItem("estado",selectEstado?.current?.value);
//     localStorage.setItem("proyecto",selectProyecto?.current?.value);
//     window.location.href = "/proyectos-en-venta-fundamenta/";
//   }
//
//   return(
//     <section {...others}>
//       {titlePage && <Text classEle="d-none" data={`Banner ${titlePage}`} typeEle="h2"/>}
//       <Search
//         selectDistrito={selectDistrito}
//         selectProyecto={selectProyecto}
//         selectEstado={selectEstado}
//         eventEle={handleSearch}
//       />
//     </section>
//   )
// }
