import * as React from "react"

export default function MapaMarker ({keyEle, children, lat ,lng}) {

  return(
    <>
      {
        (lat && lng) &&
        <div key={keyEle}>
          {children}
        </div>
      }
    </>
  )
}
