import * as React from 'react';
// import {useRef, useEffect} from "react"
import { css } from '@emotion/react';
import Text from '../../atoms/Text/Text';

export default function SelectProyecto({
  slider,
  setCodInmueble,
  setModelo,
  data,
}) {
  // const select = useRef(null);
  // console.log('data', data);
  const modelosUnicos = new Set();
  data = data.filter((inmueble) => {
    if (!modelosUnicos.has(inmueble.modelo)) {
      modelosUnicos.add(inmueble.modelo);
      return true;
    }
    return false;
  });

  const onSelect = (event) => {
    const dataSelect = event.target.value.split('|');
    setCodInmueble(dataSelect[0]);
    setModelo(dataSelect[1]);
    slider.current.slickGoTo(dataSelect[2]);
  };

  // useEffect(() => {
  //   var event = new Event('change');
  //   select.current.dispatchEvent(event);
  // }, []);

  const sectionCss = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      flex-wrap: wrap;
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
      // gap: calc(100vw * (calc(10 / var(--width_base))));
      flex-direction: column;
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(19 / var(--width_base))));
    line-height: calc(100vw * (calc(19 / var(--width_base))));
    white-space: nowrap;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
    }
  `;

  const selectCss = css`
    width: auto;
    margin-left: calc(100vw * (calc(45 / var(--width_base))));
    ${textCss};
    padding: calc(100vw * (calc(13 / var(--width_base))))
      calc(100vw * (calc(40 / var(--width_base))))
      calc(100vw * (calc(13 / var(--width_base))))
      calc(100vw * (calc(25 / var(--width_base))));
    border: calc(100vw * (calc(2 / var(--width_base)))) solid white;
    background-color: #93ba1f;
    color: white;

    @media (max-width: 767px) {
      margin-left: initial;
      border: calc(100vw * (calc(1 / var(--width_base)))) solid white;
      margin-top: calc(100vw * (calc(10 / var(--width_base))));
      padding: calc(100vw * (calc(10 / var(--width_base))))
        calc(100vw * (calc(35 / var(--width_base))))
        calc(100vw * (calc(10 / var(--width_base))))
        calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  return (
    <div css={sectionCss}>
      <Text data="Selecciona el tipo:" styleCss={textCss} colorText="white" />
      <select onChange={onSelect} css={selectCss} className="select-white">
        {data &&
          data.map((data, i) => (
            <option key={i} value={`${data.codInmueble}|${data.modelo}|${i}`}>
              {data.modelo}
            </option>
          ))}
      </select>
    </div>
  );
}
