import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import Image from "../components/atoms/Image/Image"
import { css } from "@emotion/react"
import WhatsappForm from "../components/organisms/Form/WhatsappForm"
import Text from "../components/atoms/Text/Text"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri

        template {
          ... on Wordpress_Template_Whatsapp {
            whatsapp {
              imagenFondo {
                ...imagen
              }
              logo {
                ...imagen
              }
              subtitulo
              titulo
            }
          }
        }

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function Somos({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.whatsapp

  const sectionCss = css`
    height: 100vh;
    position: relative;
    overflow: hidden;
    @media (max-width: 767px) {
      height: calc(100vh - calc(100vw * (calc(65 / var(--width_base)))));
      min-height: calc(100vw * (calc(600 / var(--width_base))));
    }

    & .gatsby-image-wrapper-constrained {
      height: 100%;
    }
  `

  const imageCss = css`
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `

  const contentCss = css`
    position: absolute;
    z-index: 1;
    top: calc(100vw * (calc(30 / var(--width_base))));
    left: 0;
    right: 0;
    //bottom: 0;
    width: calc(100vw * (calc(450 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: calc(100vw * (calc(40 / var(--width_base))));

    @media (max-width: 767px) {
      width: initial;
      margin-left: calc(100vw * (calc(15 / var(--width_base))));
      margin-right: calc(100vw * (calc(15 / var(--width_base))));
    }
  `

  const logoCss = css`
    height: calc(100vw * (calc(70 / var(--width_base))));
    width: calc(100vw * (calc(70 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
  `

  const tituloCSS = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(5 / var(--width_base))));
  `

  const subtituloCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
  `

  return (
    <LayoutGeneral sinNavegacion={false} title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <section css={sectionCss}>
        <Image
          imagen={template?.imagenFondo}
          styleCss={imageCss}
          objectFit="cover"
        />
        <div css={contentCss}>
          <Image
            imagen={template?.logo}
            styleCss={logoCss}
            objectFit="contain"
          />
          <Text
            data={template?.titulo}
            align="center"
            colorText="#8cb036"
            styleCss={tituloCSS}
            data-aos="fade-right"
            data-aos-delay="200"
          />
          <Text
            data={template?.subtitulo}
            align="center"
            styleCss={subtituloCss}
            data-aos="fade-right"
            data-aos-delay="300"
          />
          <WhatsappForm />
        </div>
      </section>
    </LayoutGeneral>
  )
}

