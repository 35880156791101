import React, {useState,useCallback, useRef} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from "../../atoms/Button/Button"
import { css } from "@emotion/react";
import getUrlParameter from "../../atoms/Utm/Utm"

export default function WhatsappForm() {

  const formGroupCss = css`
    
    .form-control{
      font-size: calc(100vw * (calc(18 / var(--width_base))));
      line-height: calc(100vw * (calc(18 / var(--width_base))));
      padding: 
        calc(100vw * (calc(6 / var(--width_base))))
        calc(100vw * (calc(12 / var(--width_base))));
      background-color: transparent;
      border: calc(100vw * (calc(1 / var(--width_base)))) solid #ced4da;
      border-radius: .25rem;
      color: #495057;
    }
    
    & select{
      background-position: top calc(100vw * (calc(17 / var(--width_base)))) right calc(100vw * (calc(12 / var(--width_base))));
    }
    
    &.form-group{
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }
    
  `;

  const buttonCss = css`
    width: 100%;
    margin-top: 0;
    background-color: white;
    border: calc(100vw * (calc(1 / var(--width_base)))) solid #8cb036;
    color: #8cb036;
    border-radius: .25rem;
    @media (max-width: 767px) {
      margin-top: 0;          
    }
  `;

  const msjCss = css`
    margin-top: calc(100vw * (calc(10 / var(--width_base))));
  `;

  let utm_campaign = getUrlParameter('utm_campaign') ? getUrlParameter('utm_campaign') : "";
  let utm_content = getUrlParameter('utm_content') ? getUrlParameter('utm_content') : "";
  let utm_medium = getUrlParameter('utm_medium') ? getUrlParameter('utm_medium') : "";
  let utm_source = getUrlParameter('utm_source') ? getUrlParameter('utm_source') : "";
  let utm_term = getUrlParameter('utm_term') ? getUrlParameter('utm_term') : "";

  const [envio, setEnvio] = useState("");
  const [correoInteres, setCorreoInteres] = useState("");
  const [linkWsp, setLinkWsp] = useState("");
  const formikRef = useRef(null);

  const onChangeProyecto = useCallback((event) => {
    const valorSelect = event?.target?.value;
    if(valorSelect){
      switch (valorSelect){
        case "Eco Prado - Entrega inmediata - San Isidro" :
          setCorreoInteres("fundamenta_ecoprado@evolta.pe");
          setLinkWsp("https://api.whatsapp.com/send?phone=51944099239&text=Hola!%20Deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20Proyecto%20Eco%20Prado");
        break;
        case "Eco Derby - Entrega inmediata - Monterrico" :
          setCorreoInteres("derby_125@evolta.pe");
          setLinkWsp("https://api.whatsapp.com/send?phone=51998328158&text=Hola!%20Deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20Proyecto%20Eco%20Derby");
        break;
        case "Eco 28 - Preventa - Miraflores" :
          setCorreoInteres("fundamenta_eco_28@evolta.pe");
          setLinkWsp("https://api.whatsapp.com/send?phone=51998328158&text=Hola!%20Deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20Proyecto%20Eco%2028");
        break;
        case "Eco Basadre - Lanzamiento - San Isidro" :
          setCorreoInteres("eco_basadre_fundamenta@evolta.pe");
          setLinkWsp("https://api.whatsapp.com/send?phone=51941641994&text=Hola%2C%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20uno%20de%20sus%20proyectos.");
        break;
        case "Eco Design - Pre Venta - Miraflores" :
          setCorreoInteres("fundamenta_eco_prado_design@evolta.pe");
          setLinkWsp("https://api.whatsapp.com/send?phone=51941641994&text=Hola%2C%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20uno%20de%20sus%20proyectos.");
        break;
        default:
          setCorreoInteres("margarita.gutierrez@fundamenta.pe");
          setLinkWsp("https://fundamenta.pe/");
        break;
      }

    }else{
      setCorreoInteres([]);
    }

    formikRef.current.setFieldValue("TipoProyecto", "");
    formikRef.current?.handleChange(event);
  }, []);

  return (
    <section>
      <Formik
        innerRef={formikRef}
        initialValues={{
          Nombres: '',
          Apellidos: '',
          Telefono: '',
          Email: '' ,
          Proyecto: ''}}
        validate={values => {
          const errors = {};
          if (!values.Nombres) errors.Nombres = 'Campo requerido';
          if (!values.Apellidos) errors.Apellidos = 'Campo requerido';
          if (!values.Telefono) errors.Telefono = 'Campo requerido';
          if (!values.Email) errors.Email = 'Campo requerido';
          else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) errors.Email = 'Correo Invalido';
          if (!values.Proyecto) errors.Proyecto = 'Campo requerido';
          return errors;
        }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          try{
            fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/1284/feedback`, {
              method: 'POST',
              body:
                'Nombres=' + values.Nombres +
                '&Apellidos=' + values.Apellidos +
                '&Telefono=' + values.Telefono +
                '&Email=' + values.Email +
                '&CorreoProyectoInteres=' + correoInteres +
                '&Proyecto=' + values.Proyecto +
                '&UtmSource=' + utm_source +
                '&UtmMedium=' + utm_medium +
                '&UtmCampaign=' + utm_campaign +
                '&UtmTerm=' + utm_term +
                '&UtmContent=' + utm_content,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
              .then(res => res.json())
              .then(json => {
                if(json.status === "mail_sent"){
                  setEnvio(true)
                  setSubmitting(false);
                  resetForm();
                  window.location.href = linkWsp;
                }
                else{
                  setEnvio(false)
                }
              })
          }catch (error) {
            console.error(error);
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="text" name="Nombres" id="Nombres" placeholder="Nombres*"/>
              <ErrorMessage className="form-error" name="Nombres" component="div" />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="text" name="Apellidos" id="Apellidos" placeholder="Apellidos*"/>
              <ErrorMessage className="form-error" name="Apellidos" component="div" />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="text" name="Telefono" id="Telefono" placeholder="Teléfono*"/>
              <ErrorMessage className="form-error" name="Telefono" component="div" />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field className="form-control" type="email" name="Email" id="Email" placeholder="Email*"/>
              <ErrorMessage className="form-error" name="Email" component="div" />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field name="Proyecto" as="select" className="form-control" onChange={(event) => onChangeProyecto(event)}>
                <option value="">Selecciona un proyecto</option>
                <option value="Eco Prado - Entrega inmediata - San Isidro">Eco Prado - Entrega inmediata - San Isidro</option>
                <option value="Eco Derby - Entrega inmediata - Monterrico">Eco Derby - Entrega inmediata - Monterrico</option>
                <option value="Eco 28 - Preventa - Miraflores">Eco 28 - Preventa - Miraflores</option>
                <option value="Eco Basadre - Lanzamiento - San Isidro">Eco Basadre - Lanzamiento - San Isidro</option>
                <option value="Eco Design - Pre Venta - Miraflores">Eco Design - Pre Venta - Miraflores</option>
              </Field>
              <ErrorMessage className="form-error" name="Proyecto" component="div" />
            </div>
            <div className="text-center" css={formGroupCss}>
              <Button typeEle="submit" styleTypeEle="submit" disabled={isSubmitting} styleEle={buttonCss}>Iniciar Whatsapp</Button>
            </div>
            { (envio === false && envio !== "") && <div css={msjCss}>Error en el envio</div>}
            { (envio === true && envio !== "") && <div css={msjCss}>Correo enviado</div>}
          </Form>
        )}
      </Formik>
    </section>
  );

}