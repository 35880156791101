import * as React from "react"
import Button from "../../atoms/Button/Button"
import { useState } from "react"
import ReactModal from "react-modal"
import { css } from "@emotion/react"
import Icon from "../../atoms/Icon/Icon"
import Image from "../../atoms/Image/Image"

if (typeof window !== "undefined") ReactModal.setAppElement("body")

export default function ModalProyecto({ image }) {
  const contentModalCss = css`
    background-color: white;
    width: auto;
    margin: auto;
    /* padding: calc(100vw * (calc(15 / var(--width_base)))); */
    padding: 0;
    position: relative;
  `

  const closeCss = css`
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    position: absolute;
    top: calc(100vw * (calc(15 / var(--width_base))));
    right: calc(100vw * (calc(15 / var(--width_base))));
    z-index: 9;
  `

  const contentFormCss = css`
    // width: calc(100vw * (calc(1200 / var(--width_base))));
    width: calc(100vw * (calc(600 / var(--width_base))));
    @media (max-width: 767px) {
      width: 100%;
    }
  `

  const imageCss = css`
    width: 100%;
  `

  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  const [isModalOpen, setIsModalOpen] = useState(true)

  return (
    <>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Modal Cotizar"
      >
        <div css={contentModalCss}>
          <div className="text-right">
            <Button
              eventEle={handleModalClose}
              styleEle={closeCss}
              typeEle="button"
            >
              <Icon typeEle="svg-close" />
            </Button>
          </div>
          <div css={contentFormCss}>
            {image && <Image imagen={image} styleCss={imageCss} />}
          </div>
        </div>
      </ReactModal>
    </>
  )
}

