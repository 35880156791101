import * as React from "react"
import CardProyecto from "../../organisms/Proyectos/CardProyecto"
import { css } from "@emotion/react"
import Text from "../../atoms/Text/Text"

export default function BannerMovilProyecto({
  dormitorios,
  tipo,
  distritos,
  isProyecto,
  urlVideo,
  imagenFondo,
  logo,
  titlePage,
  ...others
}) {
  const proyecto = {}
  proyecto.dormitorios = { nodes: dormitorios }
  proyecto.distritos = { nodes: distritos }
  proyecto.featuredImage = { node: imagenFondo }
  proyecto.video = { urlVideo }
  proyecto.template = {
    proyectoEnVenta: { logoPortada: logo },
    proximoProyecto: { logoPortada: logo },
  }

  const sectionCss = css`
    background-color: #1f1f1f;
    @media (max-width: 767px) {
      background-color: #444443;

      & .card-estado,
      & .card-price,
      & .card-direccion,
      & .card-dorm,
      & .card-distrito {
        display: none;
      }

      & .card-text {
        min-height: initial;
        background-color: #444443;
        padding-bottom: 0;
      }
    }
  `

  const imagenCss = css`
    @media (max-width: 767px) {
      height: 91vh;
      min-height: calc(100vw * (calc(200 / var(--width_base))));
    }
  `

  return (
    <section css={sectionCss} {...others}>
      {titlePage && (
        <Text classEle="d-none" data={`Banner ${titlePage}`} typeEle="h2" />
      )}
      {proyecto && (
        <CardProyecto
          data={proyecto}
          imageEleCss={imagenCss}
          isProyecto={isProyecto}
          tipo={tipo}
        />
      )}
    </section>
  )
}
