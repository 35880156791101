import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const LayoutSinNavegacion = ({ children, title, slug, image }) => {
  const titleShema = title
    ? title
    : "Innovaciones en el Mercado Inmobiliario: Tendencias 2023"
  const slugShema = slug ? slug : "/"
  const imageShema = image
    ? image
    : "https://www.fundamenta.pe/static/79115c4c30e58fafd7986c67f320ea2d/5b631/FUND._LOGO1_BLANCO542x117.jpg"

  const shemaOrganization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Fundamenta",
    url: "https://fundamenta.pe/",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Av. Juan de Aliaga 425, piso 3, Magdalena",
      postalCode: "15076",
      addressLocality: "Lima",
      addressCountry: "PE",
    },
    telephone: "+51 941641994",
    email: "soporte@fundamenta.cl",
  }

  const shemaLocalBusiness = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Fundamenta",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Av. Juan de Aliaga 425, piso 3, Magdalena",
      addressLocality: "Lima",
      addressRegion: "Lima",
      postalCode: "15076",
      addressCountry: "Perú",
    },
    telephone: "+51 941641994",
    email: "soporte@fundamenta.cl",
    openingHours: [
      "Mo 10:00-19:00",
      "Tu 10:00-19:00",
      "We 10:00-19:00",
      "Th 10:00-19:00",
      "Fr 10:00-19:00",
      "Sa 10:00-19:00",
      "Su 10:00-19:00",
    ],
  }

  const shemaArticle = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://fundamenta.pe" + slugShema,
    },
    headline: titleShema,
    image: imageShema,
    author: {
      "@type": "Person",
      name: "Juan Pérez",
    },
    publisher: {
      "@type": "Organization",
      name: "Fundamenta",
      logo: {
        "@type": "ImageObject",
        url: "https://www.fundamenta.pe/static/79115c4c30e58fafd7986c67f320ea2d/5b631/FUND._LOGO1_BLANCO542x117.jpg",
      },
    },
    datePublished: "2023-11-15",
  }
  return (
    <main className="mainsinnavgacion">
      <Helmet>
        <style>{"body{overflow:hidden;}"}</style>
        <script type="application/ld+json">
          {JSON.stringify(shemaOrganization)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(shemaLocalBusiness)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(shemaArticle)}
        </script>
      </Helmet>
      {children}
    </main>
  )
}

LayoutSinNavegacion.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutSinNavegacion
