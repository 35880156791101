import React from "react"
import Image from "../../atoms/Image/Image"
import Text from "../../atoms/Text/Text"
import { css } from "@emotion/react"
import getFecha from "../../atoms/Utilities/Fecha"
import getCategories from "../../atoms/Utilities/Categories"

export default function EntryCard({ data }) {
  const dateCss = css`
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    color: white;
    font-family: "Montserrat Regular", sans-serif;
  `
  const categoryCss = css`
    font-weight: 400;
    margin-bottom: calc(100vw * (calc(24 / var(--width_base))));
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    color: #61a41d;
  `
  const titleCss = css`
    font-weight: bold;
    margin-bottom: calc(100vw * (calc(18 / var(--width_base))));
    font-size: calc(100vw * (calc(28 / var(--width_base))));
    line-height: calc(100vw * (calc(28 / var(--width_base))));
    color: white;
    font-family: "Montserrat Regular", sans-serif;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  `
  const textCss = css`
    font-weight: 400;
    margin-bottom: 0;
    font-size: calc(100vw * (calc(15 / var(--width_base))));
    line-height: calc(100vw * (calc(17 / var(--width_base))));
    color: white;
    font-family: "Montserrat Regular", sans-serif;
    text-align: left;
    -webkit-line-clamp: 5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  `
  const cardsCss = css`
    background-color: #4a4a49;
    min-height: calc(100vw * (calc(715 / var(--width_base))));
    @media (max-width: 767px) {
      min-height: initial;
    }
  `
  const insideCardsCss = css`
    padding-inline: calc(100vw * (calc(28 / var(--width_base))));
    padding-block: calc(100vw * (calc(36 / var(--width_base))));
  `
  const imageCss = css`
    width: 100%;
    height: calc(100vw * (calc(427 / var(--width_base))));
  `

  return (
    <div css={cardsCss}>
      {data?.entrada?.imagenListado && (
        <Image imagen={data?.entrada?.imagenListado} styleCss={imageCss} />
      )}
      <div css={insideCardsCss}>
        <Text styleCss={dateCss} data={`${getFecha(data?.date, "detalle")}`} />
        <Text
          styleCss={categoryCss}
          data={getCategories(data?.categories?.nodes, "list")}
          type="content"
        />
        <Text styleCss={titleCss} data={data?.title} type="content" />
        <Text
          styleCss={textCss}
          data={data?.entrada?.extracto}
          type="content"
        />
      </div>
    </div>
  )
}

EntryCard.defaultProps = {
  data: "",
}
