import * as React from 'react';
import Image from '../../atoms/Image/Image';
import { css } from '@emotion/react';
import Text from '../../atoms/Text/Text';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export default function CardProyecto2({
  keyEle,
  data,
  isProyecto,
  imageEleCss,
  classEle,
  tipo,
  styleCss,
  isProjectsDelivered = false,
}) {
  const imageCss = css`
    height: calc(100vw * (calc(311 / var(--width_base))));
    width: 100%;
    object-fit: cover;
    ${imageEleCss};
  `;

  const cardCss = css`
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    color: white;
    display: block;
    position: relative;
    ${styleCss};
  `;

  const logoCss = css`
    width: calc(100vw * (calc(200 / var(--width_base))));
    height: calc(100vw * (calc(34 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(9 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
  `;

  const distritoCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(9 / var(--width_base))));
    text-transform: uppercase;
    border: 2px solid rgba(255, 255, 255, 0.2);
  `;

  const btnMasCss = css`
    background-color: #93ba1f;
    color: white;
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    padding: calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(31 / var(--width_base))));
  `;

  const estadoContainerCss = css`
    color: #93ba1f;
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    border-radius: calc(100vw * (calc(7 / var(--width_base))));
    text-transform: uppercase;
    position: relative;
    font-family: 'Montserrat Bold', sans-serif;
    margin-bottom: calc(100vw * (calc(9 / var(--width_base))));
  `;

  const numCss = css`
    font-family: 'Montserrat Bold', sans-serif;
    font-size: calc(100vw * (calc(52 / var(--width_base))));
    line-height: calc(100vw * (calc(63 / var(--width_base))));
    text-align: center;

    & span:not(:last-of-type) {
      margin-right: calc(100vw * (calc(30 / var(--width_base))));
      display: inline-block;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: calc(100vw * (calc(8 / var(--width_base))));
        height: calc(100vw * (calc(8 / var(--width_base))));
        background-color: #93ba1f;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100vw * (calc(-18 / var(--width_base))));
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  `;

  const infoCss = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: calc(100vw * (calc(5 / var(--width_base))));
    text-transform: uppercase;
    text-align: center;
  `;

  const text1Css = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(9 / var(--width_base))));
  `;

  const cardTextCss = css`
    background-color: #4a4a49;
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: center;
    height: calc(100vw * (calc(350 / var(--width_base))));
    padding-top: calc(100vw * (calc(28 / var(--width_base))));
    padding-left: calc(100vw * (calc(28 / var(--width_base))));
    padding-right: calc(100vw * (calc(28 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(28 / var(--width_base))));

    @media (max-width: 768px) {
      height: calc(100vw * (calc(310 / var(--width_base))));
    }
  `;

  const urlData = data?.template?.proyectoEnVenta;

  return (
    <Link
      key={keyEle}
      css={cardCss}
      to={data?.uri ? data?.uri : '/'}
      className={`project-item card-proyecto text-center district-${data?.distritos?.nodes[0]?.slug} ${classEle}`}
    >
      <div className="relative">
        {data?.featuredImage?.node ? (
          <>
            {data?.featuredImage?.node?.node ? (
              <Image
                imagen={data?.featuredImage?.node?.node}
                styleCss={imageCss}
                classEle="card-imagen"
              />
            ) : (
              <Image
                imagen={data?.featuredImage?.node}
                styleCss={imageCss}
                classEle="card-imagen"
              />
            )}
          </>
        ) : (
          <StaticImage
            css={imageCss}
            src="../../../images/sin-imagen.png"
            quality={100}
            formats={['auto', 'webp', 'avif']}
            alt="Proyecto sin imagen"
          />
        )}
      </div>
      <div css={cardTextCss}>
        {urlData?.logoPortada && (
          <Image
            imagen={urlData?.logoPortada}
            styleCss={logoCss}
            objectFit="contain"
          />
        )}

        {data?.distritos?.nodes[0]?.name && (
          <Text
            styleCss={distritoCss}
            data={data?.distritos?.nodes[0]?.name}
            classEle="card-distrito only-desk"
          />
        )}

        {!isProjectsDelivered && (
          <div css={infoCss}>
            <div css={numCss} className="card-num">
              {data?.dormitorios?.nodes &&
                data.dormitorios?.nodes.map((dormitorio, i) => (
                  <span key={i}>{dormitorio.name}</span>
                ))}
            </div>

            <Text
              data="dormitorios"
              styleCss={text1Css}
              align="center"
              classEle="card-dorm"
            />
          </div>
        )}

        <div css={estadoContainerCss}>
          {data?.estados?.nodes[0]?.name && (
            <Text data={data?.estados?.nodes[0]?.name} classEle="card-estado" />
          )}
        </div>
        <div css={btnMasCss}>Ver más</div>
      </div>
    </Link>
  );
}
