import * as React from 'react';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/Text/Text';
import { css } from '@emotion/react';
import Iframe from '../../atoms/Iframe/Iframe';

export default function BannerProyecto({
  imagen,
  logo,
  distrito,
  tipo,
  dataAdicional,
  urlVideo,
  titlePage,
  ...others
}) {
  const imageCss = css`
    // height: calc(100vw * (calc(417 / var(--width_base))));
    height: 98vh;
  `;

  const videContentCss = css`
    ${imageCss};
    overflow: hidden;
  `;

  const videoCss = css`
    width: 100% !important;
    height: calc(100vw * (calc(1172 / var(--width_base)))) !important;
    transform: scale(1.5);
    // transform: scale(1.5) translateY(-20%);
    pointer-events: none;
  `;

  const sectionCss = css`
    & .portada-imagen {
      width: 100%;
    }

    position: relative;
    &::before {
      content: '';
      position: absolute;
      height: calc(100vw * (calc(164 / var(--width_base))));
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: -moz-linear-gradient(
        50% -15.24% -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-gradient(
        linear,
        50% -15.24%,
        50% 104.78%,
        color-stop(0, rgba(255, 255, 255, 0)),
        color-stop(1, rgba(0, 0, 0, 1))
      );
      background: -o-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -ms-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#000000' ,GradientType=0)";
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=0);
    }
  `;

  const cardCss = css`
    position: absolute;
    width: calc(100vw * (calc(318 / var(--width_base))));
    min-height: calc(100vw * (calc(160 / var(--width_base))));
    background-color: #4a4a49;
    top: calc(100vw * (calc(102 / var(--width_base))));
    right: calc(100vw * (calc(170 / var(--width_base))));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
  `;

  const logoCss = css`
    height: calc(100vw * (calc(44 / var(--width_base))));
  `;

  // const textCss = css`
  //   font-size: calc(100vw * (calc(17 / var(--width_base))));
  //   line-height: calc(100vw * (calc(17 / var(--width_base))));
  //   margin-top: calc(100vw * (calc(16 / var(--width_base))));
  //   text-transform: uppercase;
  // `;

  const lineCss = css`
    height: calc(100vw * (calc(1 / var(--width_base))));
    background-color: white;
    width: calc(100vw * (calc(130 / var(--width_base))));
    margin-top: calc(100vw * (calc(30 / var(--width_base))));
  `;

  return (
    <section css={sectionCss} {...others}>
      {titlePage && (
        <Text
          classEle="d-none"
          data={`Banner proyecto ${titlePage}`}
          typeEle="h2"
        />
      )}
      {tipo === 'imagen' ? (
        <>
          {imagen && (
            <Image
              imagen={imagen}
              styleCss={imageCss}
              position="center bottom"
              classEle="portada-imagen"
            />
          )}
        </>
      ) : (
        <div css={videContentCss}>
          <Iframe url={urlVideo} styleCss={videoCss} />
        </div>
      )}
      <div css={cardCss} data-aos="fade-left" data-aos-delay="300">
        <Image imagen={logo} styleCss={logoCss} objectFit="contain" />
        <div css={lineCss}></div>
        {/* <Text data={dataAdicional} styleCss={textCss} colorText="#93BA1F"/> */}
        {/*<Text data={distrito} styleCss={textCss} colorText="#93BA1F"/>*/}
      </div>
    </section>
  );
}
