import React from 'react';
import {css} from '@emotion/react';

export default function Text({data, styleCss, classEle, typeEle, colorText, align, onlyText, ...others}) {

  if(onlyText) data = data.replace(/<[^>]*>?/g, '');

  const titleCss = css`
    //&, & p{
    &{
      text-align: ${align ? align : "initial"};
      color: ${colorText ? colorText : 'inherit'};
      ${styleCss};
    }
  `;

  // const titleH1H2Css = css`
  //   //&, & p{
  //   &{
  //
  //     ${styleCss};
  //   }
  // `;

  return (
    <>
      {
        (typeEle === "h1" && data) &&
        <h1 css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: data}} {...others}/>
      }
      {
        (typeEle === "h2" && data) &&
        <h2 css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: data}} {...others}/>
      }
      {
        (typeEle === "h3" && data) &&
        <h3 css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: data}} {...others}/>
      }
      {
        (typeEle === "h4" && data) &&
        <h4 css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: data}} {...others}/>
      }
      {
        (typeEle === "" && data) &&
        <div css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: data}} {...others}/>
      }
    </>
  );
}

Text.defaultProps = {
  data: "",
  classEle: "",
  styleCss: {},
  typeEle: "",
  onlyText: false
}