export default function getCategories (categories,place) {
  let arrCategorias = [];
  if(categories){
    categories && categories.forEach(categoria =>{
      if (place==="single") {
        arrCategorias.push(categoria.node.name);
      } else if(place==="list") {
        arrCategorias.push(categoria.name);
      }
    })
    return arrCategorias = arrCategorias.join(", ");
  }
  return '';
}