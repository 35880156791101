import * as React from "react"
import { css } from "@emotion/react";
import Text from "../../atoms/Text/Text"

export default function TextOthersPages ({data, title}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(50 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(20 / var(--width_base))));
    background-color: #4A4A49;
    @media (max-width: 767px) {
      background-color: #1f1f1f;          
    }
  `;

  const contentCss = css`
    max-width: calc(100vw * (calc(850 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      max-width: initial;
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(32 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      text-align: center;
      color: #999;
      text-transform: uppercase;
    }
  `;
  
  const textCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    
    & b, & strong, & h4{
      font-family: "Montserrat Bold", sans-serif;
    }
    & p{
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }

    & a{
      color: white;
      @media (max-width: 767px) {
        color: #8cb036;          
      }
    }
    
    @media (max-width: 767px) {
      color: #999;          
    }
  `;
  
  return(
    <section css={sectionCss}>
      <div css={contentCss}>
        <Text data={title} styleCss={titleCss} colorText="white" data-aos="fade-right" data-aos-delay="200"/>
        <Text data={data} styleCss={textCss} colorText="white" data-aos="fade-right" data-aos-delay="300"/>
      </div>
    </section>
  )
}
