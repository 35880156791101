import React from 'react';
import { Link } from "gatsby"

export default function MenuLink({ children, to, activeClassName, partiallyActive, menuItem, target, ...other}) {

  return (
    <>
      {
        (target === "_blank" && to !== "") &&
        <a
          className="menu__link"
          href={to}
          target="_blank"
          rel="noreferrer noopener">
          {children}
        </a>
      }
      {
        (target !== "_blank" && to !== "") &&
        <Link
          className="menu__link"
          to={to}
          activeClassName="is-active"
          target={target}
          partiallyActive={partiallyActive}
          {...other}>
          {children}
        </Link>
      }
      {
        (target !== "_blank" && to === "") &&
        <a
          className="menu__link"
          href={to}>
          {children}
        </a>
      }

    </>
  );

}

