import * as React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useStaticQuery, graphql } from 'gatsby';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import { css } from '@emotion/react';
import { useCallback, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

const LayoutGeneral = ({
  children,
  cotizar,
  sinNavegacion,
  isProyecto = false,
  title,
  slug,
  image,
}) => {
  const resetear = useCallback(() => {
    if (!cotizar) {
      // localStorage.setItem("distrito", "");
      // localStorage.setItem("estado", "");
      // localStorage.setItem("proyecto", "");
    }
  }, [cotizar]);

  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      once: true,
    });
    resetear();
  }, [resetear]);

  const {
    wordpress: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wordpress {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);

  const mainCss = css`
    ${sinNavegacion &&
    'margin-top: calc(100vw * (calc(100 / var(--width_base))));'};
    overflow: hidden;
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(65 / var(--width_base))));
    }
  `;

  const titleShema = title
    ? title
    : 'Innovaciones en el Mercado Inmobiliario: Tendencias 2023';
  const slugShema = slug ? slug : '/';
  const imageShema = image
    ? image
    : 'https://www.fundamenta.pe/static/79115c4c30e58fafd7986c67f320ea2d/5b631/FUND._LOGO1_BLANCO542x117.jpg';

  const shemaOrganization = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Fundamenta',
    url: 'https://fundamenta.pe/',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Av. Juan de Aliaga 425, piso 3, Magdalena',
      postalCode: '15076',
      addressLocality: 'Lima',
      addressCountry: 'PE',
    },
    telephone: '+51 941641994',
    email: 'soporte@fundamenta.cl',
  };

  const shemaLocalBusiness = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'Fundamenta',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Av. Juan de Aliaga 425, piso 3, Magdalena',
      addressLocality: 'Lima',
      addressRegion: 'Lima',
      postalCode: '15076',
      addressCountry: 'Perú',
    },
    telephone: '+51 941641994',
    email: 'soporte@fundamenta.cl',
    openingHours: [
      'Mo 10:00-19:00',
      'Tu 10:00-19:00',
      'We 10:00-19:00',
      'Th 10:00-19:00',
      'Fr 10:00-19:00',
      'Sa 10:00-19:00',
      'Su 10:00-19:00',
    ],
  };

  const shemaArticle = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://fundamenta.pe' + slugShema,
    },
    headline: titleShema,
    image: imageShema,
    author: {
      '@type': 'Person',
      name: 'Juan Pérez',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Fundamenta',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.fundamenta.pe/static/79115c4c30e58fafd7986c67f320ea2d/5b631/FUND._LOGO1_BLANCO542x117.jpg',
      },
    },
    datePublished: '2023-11-15',
  };

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(shemaOrganization)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(shemaLocalBusiness)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(shemaArticle)}
        </script>
        {slug && (
          <link rel="canonical" href={`https://www.fundamenta.pe${slug}`} />
        )}

        {slug === '/blog/' && (
          <link rel="next" href="https://www.fundamenta.pe/blog/page/2" />
        )}
        {slug === '/blog/page/2' && (
          <link rel="prev" href="https://www.fundamenta.pe/blog/" />
        )}
        {slug === '/blog/page/2' && (
          <link rel="next" href="https://www.fundamenta.pe/blog/page/3" />
        )}

        {slug === '/blog/page/3' && (
          <link rel="prev" href="https://www.fundamenta.pe/blog/page/2" />
        )}
        {slug === '/blog/page/3' && (
          <link rel="next" href="https://www.fundamenta.pe/blog/page/4" />
        )}

        {slug === '/blog/page/4' && (
          <link rel="prev" href="https://www.fundamenta.pe/blog/page/3" />
        )}
        {slug === '/blog/page/4' && (
          <link rel="next" href="https://www.fundamenta.pe/blog/page/5" />
        )}

        {slug === '/blog/page/5' && (
          <link rel="prev" href="https://www.fundamenta.pe/blog/page/4" />
        )}
      </Helmet>
      <div>
        <Header cotizar={cotizar} className={!sinNavegacion && 'only-mov'} />
        <main css={mainCss}>{children}</main>
        {sinNavegacion && <Footer cotizar={cotizar} isProyecto={isProyecto} />}
      </div>
    </SEOContext.Provider>
  );
};

LayoutGeneral.propTypes = {
  children: PropTypes.node.isRequired,
};

LayoutGeneral.defaultProps = {
  cotizar: false,
  sinNavegacion: true,
};

export default LayoutGeneral;
