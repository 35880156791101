import React from 'react';
import MenuLink from "../MenuLink/MenuLink"
import HijosMenuItem from '../Utilities/HijosMenuItem';

export const CreateLocalLink = (menuItem, wordPressUrl) => {
  const { url } = menuItem;

  if (url === '#' || url === null || url === undefined) {
    return '';
  }
  let newUri = url.replace(wordPressUrl, '');
  return newUri;
};

export default function MenuItem({ menuItem, wordPressUrl, classEle }) {
  const menuItemsChildrens = menuItem.childItems
  return (
    <li className={`${classEle ? classEle: 'menu__item'}`}>
      <MenuLink to={CreateLocalLink(menuItem, wordPressUrl)} target={menuItem.target}>{menuItem.label}</MenuLink>
      {
        (menuItemsChildrens?.edges?.length) ?
          <HijosMenuItem menuItems={menuItemsChildrens} wordPressUrl={wordPressUrl}/>
        :
        <></>
      }
    </li>
  );

}