import * as React from "react"
import { css } from "@emotion/react";
import Text from "../../atoms/Text/Text"

export default function DescripcionSomos ({data, titlePage}) {

  const sectionCss = css`
    background-color: #5E5E5E;
    padding-top: calc(100vw * (calc(75 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(107 / var(--width_base))));
    @media (max-width: 767px) {
      background-color: #444443;
      padding-top: calc(100vw * (calc(50 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
    }
  `;
  
  const contentCss = css`
    max-width: calc(100vw * (calc(534 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(26 / var(--width_base))));
    line-height: calc(100vw * (calc(26 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    font-family: "Montserrat Regular", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      color: #999;
    }
  `;

  const descripcionItemCss = css`
    &:not(:last-of-type){
      margin-bottom: calc(100vw * (calc(54 / var(--width_base))));
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(30 / var(--width_base))));          
      }
    }
  `;

  return(
    <section css={sectionCss}>
      {titlePage && <Text classEle="d-none" data={`Descripción ${titlePage}`} typeEle="h2"/>}
      <div css={contentCss}>
        {
          data && data.map((dato, i)=>(
            <div key={i} css={descripcionItemCss}>
              <Text data={dato?.titulo} align="center" colorText="#93BA1F" styleCss={titleCss} data-aos="fade-right" data-aos-delay="200"/>
              <Text data={dato?.texto} align="center" colorText="white" styleCss={textCss} data-aos="fade-right" data-aos-delay="300"/>
            </div>
          ))
        }
      </div>
    </section>
  )
}
