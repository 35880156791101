import * as React from 'react';
import Text from '../../atoms/Text/Text';
import { css } from '@emotion/react';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import List from '../List/List';

export default function NavigationFooter({
  direction,
  facebook,
  instagram,
  youtube,
  linkedin,
  menu,
  wordPressUrl,
}) {
  const navigationCss = css`
    position: relative;
    background-color: #93ba1f;
    color: white;
    padding-top: calc(100vw * (calc(23 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(12 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(28 / var(--width_base))));
    @media (max-width: 767px) {
      background-color: #444443;
      color: #999;
      padding-top: initial;
      padding-bottom: initial;
      margin-bottom: calc(100vw * (calc(13 / var(--width_base))));
    }

    &::before {
      @media (max-width: 767px) {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(100vw * (calc(15 / var(--width_base))));
        right: calc(100vw * (calc(15 / var(--width_base))));
        display: block;
        background-color: #cfcfcf;
        height: calc(100vw * (calc(1 / var(--width_base))));
      }
    }
  `;

  const navigationContentCss = css`
    max-width: calc(100vw * (calc(1024 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  `;

  const redesCss = css`
    display: flex;
    align-items: flex-end;
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-bottom: initial;
    }
  `;

  const redesLinksCss = css`
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(13 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(13 / var(--width_base))));
    }
  `;

  /*
  const directionCss = css`
    font-size: calc(100vw * (calc(15 / var(--width_base))));
    @media (max-width: 767px) {
      background-color: #1f1f1f;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      padding-top: calc(100vw * (calc(12 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(12 / var(--width_base))));
      text-align: center;
    }
  `;
  * */

  const siguenosCss = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    margin-right: calc(100vw * (calc(30 / var(--width_base))));
    font-family: 'Montserrat Bold', sans-serif;
    @media (max-width: 767px) {
      width: 100%;
      margin-right: initial;
      padding-top: calc(100vw * (calc(25 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(13 / var(--width_base))));
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      background-color: #444443;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
      color: white;
    }
  `;

  const iconCss = css`
    &:not(:last-of-type) {
      margin-right: calc(100vw * (calc(34 / var(--width_base))));
    }
  `;

  const menuListCss = css`
    column-count: 2;
    column-gap: calc(100vw * (calc(45 / var(--width_base))));
    break-inside: avoid;
    margin-left: calc(100vw * (calc(13 / var(--width_base))));
    margin-right: calc(100vw * (calc(148 / var(--width_base))));
    @media (max-width: 767px) {
      margin-right: calc(100vw * (calc(0 / var(--width_base))));
      margin-left: calc(100vw * (calc(0 / var(--width_base))));
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      padding-top: calc(100vw * (calc(17 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(12 / var(--width_base))));
      column-gap: calc(100vw * (calc(10 / var(--width_base))));
    }
    & .menu__link {
      color: white;
      font-size: calc(100vw * (calc(15 / var(--width_base))));
      line-height: calc(100vw * (calc(15 / var(--width_base))));
      // white-space: nowrap;
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(14 / var(--width_base))));
        line-height: calc(100vw * (calc(18 / var(--width_base))));
        color: #999;
      }
    }
    & .menu__item {
      margin-bottom: calc(100vw * (calc(13 / var(--width_base))));
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(5 / var(--width_base))));
      }
    }
  `;

  return (
    <div css={navigationCss}>
      <div css={navigationContentCss}>
        <List
          menuItems={menu}
          wordPressUrl={wordPressUrl}
          styleCss={menuListCss}
        />
        <div className="column-center">
          <div css={redesCss}>
            <Text styleCss={siguenosCss} data="SÍGUENOS" />
            <div css={redesLinksCss}>
              <Button
                styleEle={iconCss}
                typeEle="linkTarget"
                link={facebook}
                aria-label="facebook"
              >
                <Icon typeEle="facebook" />
              </Button>
              <Button
                styleEle={iconCss}
                typeEle="linkTarget"
                link={instagram}
                aria-label="instagram"
              >
                <Icon typeEle="instagram" />
              </Button>
              <Button
                styleEle={iconCss}
                typeEle="linkTarget"
                link={youtube}
                aria-label="yotube"
              >
                <Icon typeEle="youtube" />
              </Button>
              <Button
                styleEle={iconCss}
                typeEle="linkTarget"
                link={linkedin}
                aria-label="linkedin"
              >
                <Icon typeEle="linkedin" />
              </Button>
            </div>
          </div>
          {/*<Text styleCss={directionCss} data={direction}/>*/}
        </div>
      </div>
    </div>
  );
}
