import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { css } from "@emotion/react"
import Iframe from "../components/atoms/Iframe/Iframe"
import Text from "../components/atoms/Text/Text"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri

        template {
          ... on Wordpress_Template_Tour {
            templateName
            tour {
              urlRecorridoPage
              tituloPage
            }
          }
        }

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function Tour({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.tour

  const sectionCss = css`
    padding: calc(100vw * (calc(50 / var(--width_base))))
      calc(100vw * (calc(10 / var(--width_base))));
    background-color: #4a4a49;
  `

  const titleCss = css`
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <section css={sectionCss}>
        <Text
          data={template?.tituloPage}
          colorText="white"
          align="center"
          styleCss={titleCss}
          data-aos="fade-right"
          data-aos-delay="200"
        />
        <Iframe url={template?.urlRecorridoPage} />
      </section>
    </LayoutGeneral>
  )
}

