import * as React from "react"
import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image"
// import Button from "../../atoms/Button/Button"
import Fancybox from "../../atoms/Fancybox/Fancybox"

export default function SliderItemCotizar ({keyEle, data, eventEle}) {

  const imageCss = css`
    width: 100%;
    object-fit: contain;
    height: calc(100vw * (calc(440 / var(--width_base))));
    @media (max-width: 767px) {
      height: calc(100vw * (calc(310 / var(--width_base))));          
    }
  `;
  
  // const submitCss = css`
  //   margin-top: calc(100vw * (calc(20 / var(--width_base))));
  // `;

  return(
    <div key={keyEle} className="slider-carusel__item">
      {
        data?.urlImagen &&
        <div>
          {/* <img src={data?.urlImagen} alt={data?.modelo} css={imageCss} className="slider-carusel__imagen"/> */}
          <Fancybox options={{ infinite: false }}>
                <img
                  css={imageCss}
                  className="slider-carusel__imagen"
                  alt={data?.modelo}
                  src={data?.urlImagen}
                  data-fancybox="gallery2"
                  data-caption={data?.modelo}
                  data-src={data?.urlImagen}
                />
              </Fancybox>
        </div>
      }
      {
        (data?.urlImagen === undefined || data?.urlImagen === null) &&
        <div>
          <StaticImage
            src="../../../images/sin-imagen.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Sin Imagen"
            objectFit="contain"
            css={css`${imageCss};border: calc(100vw * (calc( 1/ var(--width_base)))) solid;`}
          />
        </div>
      }
      {/* <div className="text-center">
        <Button
          styleEle={submitCss}
          typeEle="button"
          eventEle={()=>eventEle(data?.modelo, data?.codInmueble, data?.nroDormitorio,data?.nroBanios)}
          styleTypeEle="submit">
          COTIZA AQUÍ
        </Button>
      </div> */}
    </div>
  )
}
