import React, { useState } from 'react';
import LayoutGeneral from '../components/organisms/Layout/LayoutGeneral';
import Seo from '../components/atoms/Seo/Seo';
import { graphql } from 'gatsby';
import GridBlog from '../components/template/Blog/GridBlog';
import BannerOthersPages from '../components/organisms/OthersPages/BannerOthersPages';
import SearchBlog from '../components/template/Blog/BarSearchBlog';

export default function ArchivePost({ data, pageContext }) {
  const posts = data?.wordpress?.posts?.nodes;
  const [postData, setPostData] = useState(posts);
  const page = data?.wordpress?.page?.template;

  const { pageNumber, hasNextPage, postsPerPage, total } = pageContext;

  let titlePage = 'Blog';

  let slug_how = data?.wordpress?.page?.uri;

  if (pageNumber > 1) {
    titlePage = `Blog - Página ${pageNumber}`;
    slug_how = `${slug_how}page/${pageNumber}`;
  }

  return (
    <LayoutGeneral title={data?.wordpress?.page?.title} slug={slug_how}>
      <Seo title={titlePage} />
      {page?.blog.imagenPortada && (
        <BannerOthersPages
          title={page?.blog.textoPortada}
          imagen={page?.blog.imagenPortada}
          imagenMovil={page?.blog.imagenMovilPortada}
          typeStyle="banner-page"
          titlePage={page?.title}
        />
      )}
      <SearchBlog titlePage={page.title} setData={setPostData} data={posts} />
      {postData && (
        <GridBlog
          posts={postData}
          pageNumber={pageNumber}
          hasNextPage={hasNextPage}
          postsPerPage={postsPerPage}
          total={total}
        />
      )}
    </LayoutGeneral>
  );
}

export const pageQuery = graphql`
  query GET_POSTS($ids: [ID], $postsPerPage: Int!) {
    wordpress {
      posts(first: $postsPerPage, where: { in: $ids }) {
        nodes {
          title
          slug
          date
          modified
          databaseId
          uri
          categories {
            nodes {
              name
            }
          }
          author {
            node {
              name
            }
          }
          entrada {
            extracto
            textoCompleto
            imagenPortada {
              ...imagen
            }
            imagenMovilPortada {
              ...imagen
            }
            imagenListado {
              ...imagen
            }
          }
        }
      }
      page(id: "1453", idType: DATABASE_ID) {
        template {
          ... on Wordpress_Template_Blog {
            templateName
            blog {
              textoPortada
              imagenPortada {
                ...imagen
              }
              imagenMovilPortada {
                ...imagen
              }
            }
          }
        }
        title
        uri
      }
    }
  }
`;
