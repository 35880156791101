import * as React from "react"
import Iframe from "../../atoms/Iframe/Iframe"
import { css } from "@emotion/react"
import Text from "../../atoms/Text/Text"

export default function RecorridoProyecto ({title, url, titlePage}) {

  const sectionCss = css`
    background-color: #444443;
    padding-top: calc(100vw * (calc(34 / var(--width_base))));
    @media (max-width: 767px) {
      background-color: #444443;
      padding-bottom: calc(100vw * (calc(28 / var(--width_base))));
    }
  `;
  
  const titleCss = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(32 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
      color: #999;
      max-width: calc(100vw * (calc(200 / var(--width_base))));
      margin-left: auto;
      margin-right: auto;
      margin-bottom: calc(100vw * (calc(28 / var(--width_base))));
    }
  `;

  const iframeCss = css`
    border: calc(100vw * (calc(1 / var(--width_base)))) solid #444443;
  `;

  return(
    <section className="line-0" css={sectionCss}>
      {titlePage && <Text classEle="d-none" data={`Recorrido virtual ${titlePage}`} typeEle="h2"/>}
      <Text data={title} styleCss={titleCss} align="center" colorText="white" data-aos="fade-right" data-aos-delay="200"/>
      <Iframe url={url} styleCss={iframeCss}/>
    </section>
  )
}
