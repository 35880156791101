import React from 'react';
import { css } from "@emotion/react"
import { Link } from "gatsby"
import EntryCard from "../../moleculas/EntryCard/EntryCard"

export default function PostEntry({keyEle, link, data}) {

  // ESTILOS

  const cardCss = css`
      width: calc(100vw * (calc(473 / var(--width_base))));
    transition: background-color .3s linear;
    padding: calc(100vw * (calc(25 / var(--width_base)))) calc(100vw * (calc(20 / var(--width_base))));
    &:hover{
     background-color: transparent; 
    }
    @media (max-width: 767px) {
  padding: 0;
  padding-bottom: calc(100vw * (calc(25 / var(--width_base))));
  width: auto;
    }
  `;

  // LOGICA


  return (
    <Link to={link} key={keyEle} css={cardCss}>
      <EntryCard data={data} />
    </Link>
  );

}