import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import LayoutGeneral from '../components/organisms/Layout/LayoutGeneral';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import SearchProyecto from '../components/template/Proyectos/SearchProyecto';
import { parserString } from '../helper/parserString';
import TabsProyectos from '../components/template/Proyectos/TabsProyectos';

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        template {
          ... on Wordpress_Template_ProyectosEnVenta {
            proyectosEnVenta {
              titulo
              subtitulo
            }
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }

      pageInicio: page(id: "26", idType: DATABASE_ID) {
        title
        template {
          ... on Wordpress_Template_Inicio {
            inicio {
              tituloProyectosEnVenta
              tituloProyectosEntregados
              proyectosEntregados {
                ... on Wordpress_Proyecto {
                  databaseId
                  title
                  uri
                  featuredImage {
                    node {
                      ...imagen
                    }
                  }
                  distritos {
                    nodes {
                      slug
                      databaseId
                      name
                    }
                  }
                  template {
                    ... on Wordpress_Template_ProyectoEnVenta {
                      proyectoEnVenta {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                        lanzamientoDescripcion
                        precioDescripcion
                      }
                    }
                    ... on Wordpress_Template_ProximoProyecto {
                      proximoProyecto {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                      }
                    }
                  }
                  estados {
                    nodes {
                      name
                      slug
                    }
                  }
                  dormitorios {
                    nodes {
                      name
                    }
                  }
                }
              }
              proyectosEnVenta {
                ... on Wordpress_Proyecto {
                  databaseId
                  title
                  uri
                  featuredImage {
                    node {
                      ...imagen
                    }
                  }
                  distritos {
                    nodes {
                      slug
                      databaseId
                      name
                    }
                  }
                  template {
                    ... on Wordpress_Template_ProyectoEnVenta {
                      proyectoEnVenta {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                        lanzamientoDescripcion
                        precioDescripcion
                      }
                    }
                    ... on Wordpress_Template_ProximoProyecto {
                      proximoProyecto {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                      }
                    }
                  }
                  estados {
                    nodes {
                      name
                      slug
                    }
                  }
                  dormitorios {
                    nodes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function ProyectosVenta({ data }) {
  const page = data?.wordpress?.page;
  const pageInicio = data?.wordpress?.pageInicio;
  const template = page?.template?.proyectosEnVenta;
  const templateInicio = pageInicio?.template?.inicio;

  useEffect(() => {
    const district = localStorage.getItem('distrito');
    const value = parserString(district);
    if (value) {
      const projectCard = document.querySelectorAll('.project-item');
      projectCard.forEach((element) => {
        if (value === '') {
          element.classList.remove('d-none');
        } else {
          const hastClass = element.classList.contains(value);
          if (hastClass) {
            element.classList.remove('d-none');
          } else {
            element.classList.add('d-none');
          }
        }
      });
    }
    localStorage.removeItem('distrito');

    window.addEventListener('beforeunload', function (e) {
      localStorage.removeItem('distrito');
    });
  }, []);

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <SearchProyecto titlePage={page.title} />
      <TabsProyectos dataPage={templateInicio} />
    </LayoutGeneral>
  );
}
