import React from "react"
import { graphql, Link } from "gatsby"
import LayoutSinNavegacion from "../components/organisms/Layout/LayoutSinNavegacion"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { css } from "@emotion/react"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      pdf(id: $id, idType: DATABASE_ID) {
        title
        uri

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function Contacto({ data }) {
  const page = data?.wordpress?.pdf
  const pageUrlApros = data?.wordpress?.pdf?.uri
  const domain = process.env.GATSBY_PAGINA_COMPILADOR
  const cssIframe = css`
    width: 100%;
    height: 100vh;
    border: none;
  `

  return (
    <LayoutSinNavegacion title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <Link className="d-none" to="/">Inicio</Link>
      <iframe
        css={cssIframe}
        src={`${domain}${pageUrlApros}`}
        frameborder="0"
        title="PDF"
      ></iframe>
    </LayoutSinNavegacion>
  )
}
