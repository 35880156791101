import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NavegationHeader from '../../moleculas/Navegation/NavegationHeader';
import { css } from '@emotion/react';
import GeneralMenu from '../../moleculas/Menu/GeneralMenu';
import { useRef } from 'react';

export default function Header({ cotizar, ...other }) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        page(id: "828", idType: DATABASE_ID) {
          template {
            ... on Wordpress_Template_Opciones {
              opciones {
                celularHeader
                linkWhatsappHeader
                logoGeneral {
                  ...imagen
                }
                logoOscuroGeneral {
                  ...imagen
                }
              }
            }
          }
        }
      }
    }
  `);

  const page = data?.wordpress?.page;
  const template = page?.template?.opciones;
  const logo = template?.logoGeneral;
  const logoOscuro = template?.logoOscuroGeneral;
  const nrnCelular = template?.celularHeader;
  const linkWhatsapp = template?.linkWhatsappHeader;
  const menuEle = useRef();

  const headerCss = css`
    padding-left: calc(100vw * (calc(54 / var(--width_base))));
    padding-right: calc(100vw * (calc(54 / var(--width_base))));
    // height: calc(100vw * (calc(62 / var(--width_base))));
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    transition: background-color 0.3s ease, transform 0.3s ease;
    // @media (max-width: 1366px) {
    //   height: calc(100vw * (calc(78 / 1366)));
    // }
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      // background-color: transparent;
      // height: calc(100vw * (calc(65 / var(--width_base))));
      z-index: 999;
    }

    &.header-active {
      @media (max-width: 767px) {
        background-color: transparent;
      }
    }

    &.header-active .icon-menu {
      display: none;
    }

    &:not(.header-active) .icon-close {
      display: none;
    }
  `;

  return (
    <>
      <header css={headerCss} {...other}>
        <NavegationHeader
          logo={logo}
          logoOscuro={logoOscuro}
          cel={nrnCelular}
          wsp={linkWhatsapp}
          cotizar={cotizar}
          menuEle={menuEle}
        />
      </header>
      <GeneralMenu logo={logoOscuro} menuEle={menuEle} />
    </>
  );
}

Header.defaultProps = {
  cotizar: false,
};
