import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';

export default function Image({
  imagen,
  classEle,
  styleCss,
  styleContentCss,
  objectFit,
  position,
}) {
  styleCss = css`
    width: -webkit-fill-available;
    &.gatsby-image-wrapper img {
      ${styleCss}
    }
    ${styleContentCss}
  `;

  return (
    <>
      {imagen && (
        <>
          {imagen?.mimeType === 'image/gif' ? (
            <img
              css={styleCss}
              src={imagen?.sourceUrlSharp?.publicURL}
              alt={`${imagen.alt ? imagen?.alt : imagen?.title}`}
            />
          ) : (
            <GatsbyImage
              css={styleCss}
              objectFit={objectFit}
              className={` ${classEle}`}
              image={imagen?.sourceUrlSharp?.childImageSharp?.gatsbyImageData}
              objectPosition={position}
              alt={`${imagen?.alt ? imagen?.alt : imagen?.title}`}
            />
          )}
        </>
      )}
    </>
  );
}

export const query_imagen = graphql`
  fragment imagen on Wordpress_MediaItem {
    altText
    title
    mimeType
    srcSet
    sourceUrl
    sourceUrlSharp {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
      }
      publicURL
    }
  }
`;
Image.defaultProps = {
  imagen: null,
  classEle: '',
  styleCss: {},
  objectFit: 'cover',
  position: '50% 50%',
};
