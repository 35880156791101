import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from "../../atoms/Button/Button"
import { css } from "@emotion/react";

export default function ContactoForm({ bgColorInput, bgColorSubmit }) {

  const formContainerCss = css`
    display: flex;
    flex-wrap: wrap;
  `;
  // const textareaContainerCss = css`
  //   height: 100%;
  // `;
  const formGroupCss = css`
    padding-left: calc(100vw * (calc(5 / var(--width_base))));
    padding-right: calc(100vw * (calc(5 / var(--width_base))));
    &.form-group{
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(15 / var(--width_base))));          
      }
    }
  `;
  const buttonCss = css`
    width: 100%;
    margin-top: calc(100vw * (calc(25 / var(--width_base))));
    @media (max-width: 767px) {
      margin-top: 0;          
    }
  `;

  const [envio, setEnvio] = useState("");

  return (
    <section>
      <Formik
        initialValues={{
          NombresYApellidos: '',
          Telefono: '',
          DNI: '',
          Correo: '',
          Mensaje: ''
        }}
        validate={values => {
          const errors = {};
          if (!values.NombresYApellidos) errors.NombresYApellidos = 'Campo requerido';
          if (!values.DNI) errors.DNI = 'Campo requerido';
          if (!values.Telefono) errors.Telefono = 'Campo requerido';
          if (!values.Correo) errors.Correo = 'Campo requerido';
          else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Correo)) errors.Correo = 'Correo Invalido';
          if (!values.Mensaje) errors.Mensaje = 'Campo requerido';
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          try {
            fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/1170/feedback`, {
              method: 'POST',
              body:
                'NombresYApellidos=' + values.NombresYApellidos +
                '&DNI=' + values.DNI +
                '&Telefono=' + values.Telefono +
                '&Correo=' + values.Correo +
                '&Mensaje=' + values.Mensaje,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
              .then(res => res.json())
              .then(json => {
                if (json.status === "mail_sent") {
                  setEnvio(true)
                  setSubmitting(false);
                  resetForm();
                }
                else {
                  setEnvio(false)
                }
              })
          } catch (error) {
            console.error(error);
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form css={formContainerCss}>
            <div className="column column-50">
              <div className="form-group column column-100" css={formGroupCss}>
                <Field className="form-control" type="text" name="NombresYApellidos" id="NombresYApellidos" placeholder="NOMBRE y APELLIDO*" />
                <ErrorMessage className="form-error" name="NombresYApellidos" component="div" />
              </div>
              <div className="form-group column column-100" css={formGroupCss}>
                <Field className="form-control" type="text" name="Telefono" id="Telefono" placeholder="TELÉFONO*" />
                <ErrorMessage className="form-error" name="Telefono" component="div" />
              </div>
              <div className="form-group column column-100" css={formGroupCss}>
                <Field className="form-control" type="number" name="DNI" id="DNI" placeholder="DNI*" />
                <ErrorMessage className="form-error" name="DNI" component="div" />
              </div>
              <div className="form-group column column-100" css={formGroupCss}>
                <Field className="form-control" type="email" name="Correo" id="Correo" placeholder="CORREO*" />
                <ErrorMessage className="form-error" name="Correo" component="div" />
              </div>
            </div>
            <div className="column column-50">
              <div className="form-group h-full" css={formGroupCss}>
                <Field className="form-control h-full" as="textarea" name="Mensaje" id="Mensaje" placeholder="MENSAJE*" />
                <ErrorMessage className="form-error" name="Mensaje" component="div" />
              </div>
            </div>

            <div className="text-center column column-100" css={formGroupCss}>
              <Button typeEle="submit" styleTypeEle="submit" disabled={isSubmitting} styleEle={buttonCss}>ENVIAR</Button>
            </div>
            {(envio === false && envio !== "") && <div className="">Error en el envio</div>}
            {(envio === true && envio !== "") && <div className="">Correo enviado</div>}
          </Form>
        )}
      </Formik>
    </section>
  );

}