import * as React from "react"
import { css } from "@emotion/react";
import Image from "../../atoms/Image/Image"
import Text from "../../atoms/Text/Text"

export default function SliderItemSomos ({keyEle, data}) {

  const cardCss = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    //margin-right: calc(100vw * (calc(24 / var(--width_base))));
    //margin-left: calc(100vw * (calc(24 / var(--width_base))));
  `;

  const cardImageCss = css`
    height: calc(100vw * (calc(107 / var(--width_base))));
    @media (max-width: 767px) {
      height: calc(100vw * (calc(80 / var(--width_base))));          
    }
  `;

  const cardTextCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    font-family: "Montserrat Regular", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      color: #999;
    }
  `;

  const cardTitleCss = css`
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    font-family: "Montserrat Bold",sans-serif;
    margin-top: calc(100vw * (calc(18 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(24 / var(--width_base))));
    
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;
  const outCardCss = css`

  margin-bottom: calc(100vw * (calc(24 / var(--width_base))));
  @media (max-width: 767px) {
    
  }
`;

  return(
    <div key={keyEle} css={outCardCss}>
      <div css={cardCss}>
        <Image imagen={data.imagen} objectFit="contain" styleCss={cardImageCss}/>
        <Text data={data?.titulo} styleCss={cardTitleCss} align="center" colorText="#93BA1F" data-aos="fade-right" data-aos-delay="200"/>
        <Text data={data?.texto} styleCss={cardTextCss} align="center" data-aos="fade-right" data-aos-delay="300"/>
      </div>
    </div>
  )
}
