import * as React from "react"
import { css } from "@emotion/react"
import Button from "../../atoms/Button/Button"
//import Image from "../../atoms/Image/Image"
import { useStaticQuery, graphql } from "gatsby"
import MenuItem from "../../atoms/MenuItem/MenuItem"
import Icon from "../../atoms/Icon/Icon"

export default function GeneralMenu({ logo, menuEle }) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        menuItems(where: { parentId: "", location: HCMS_MENU_HEADER }) {
          nodes {
            id
            label
            url
            title
            target
            childItems(before: "") {
              edges {
                node {
                  id
                  label
                  url
                  title
                  target
                }
              }
            }
          }
        }
        generalSettings {
          url
        }
      }
    }
  `)

  const listMenu = data?.wordpress?.menuItems?.nodes
  const wordPressUrl = data?.wordpress?.generalSettings?.url

  const menuCss = css`
    position: fixed;
    z-index: 110;
    top: calc(100vw * (calc(62 / var(--width_base))));
    bottom: calc(100vw * (calc(180 / var(--width_base))));
    right: calc(100vw * (calc(-409 / var(--width_base))));
    width: calc(100vw * (calc(409 / var(--width_base))));
    min-height: calc(100vw * (calc(500 / var(--width_base))));
    background-color: #4b4a4a;
    color: white;
    padding: calc(100vw * (calc(18 / var(--width_base)))) 0
      calc(100vw * (calc(33 / var(--width_base))))
      calc(100vw * (calc(5 / var(--width_base))));
    display: block;
    transition: transform 0.5s cubic-bezier(0.16, 0.68, 0.43, 0.99);

    &.is-active {
      /* display: block; */
      transform: translate3d(
        calc(100vw * (calc(-409 / var(--width_base)))),
        0,
        0
      ) !important;
      transition: transform 0.5s cubic-bezier(0.16, 0.68, 0.43, 0.99);
      @media (max-width: 767px) {
        // transform: translate3d( calc(100vw * (calc(-297 / var(--width_base)))), 0, 0 ) !important;
      }
    }

    @media (max-width: 1366px) {
      top: calc(100vw * (calc(95 / var(--width_base))));
    }

    @media (max-width: 767px) {
      width: 100%;
      // width: calc(100vw * (calc(297 / var(--width_base))));
      // right: 100%;
      top: 0;
      left: auto;
      height: 100vh;
      overflow: auto;
      border-right: calc(100vw * (calc(3 / var(--width_base)))) solid white;
      padding: calc(100vw * (calc(90 / var(--width_base)))) 0
        calc(100vw * (calc(33 / var(--width_base)))) 0;
    }
  `

  const buttonCss = css`
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    line-height: calc(100vw * (calc(12 / var(--width_base))));
    padding: calc(100vw * (calc(8 / var(--width_base))))
      calc(100vw * (calc(32 / var(--width_base))));
    background-color: #80bc00;
    color: white;
    text-transform: uppercase;
    font-family: "Montserrat Regular", sans-serif;
    margin-top: calc(100vw * (calc(17 / var(--width_base))));

    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(25 / var(--width_base))));
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
    }
  `

  /*
  const logoCss = css`
    height: calc(100vw * (calc(22 / var(--width_base))));
    width: calc(100vw * (calc(103 / var(--width_base))));
    @media (max-width: 767px) {
      height: calc(100vw * (calc(37 / var(--width_base))));
      width: calc(100vw * (calc(170 / var(--width_base))));
    }
  `;
  * */

  const headerCss = css`
    padding-left: calc(100vw * (calc(15 / var(--width_base))));
    padding-right: calc(100vw * (calc(15 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    display: flex;
    justify-content: end;
    align-items: center;

    button {
      @media (min-width: 768px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
  `

  const listCss = css`
    & .menu__link {
      color: white;
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      font-family: "Montserrat Regular", sans-serif;
      border-bottom: calc(100vw * (calc(1 / var(--width_base)))) solid #80bc00;
      padding-top: calc(100vw * (calc(20 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(20 / var(--width_base))));
      width: 100%;
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      line-height: calc(100vw * (calc(12 / var(--width_base))));
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(14 / var(--width_base))));
        line-height: calc(100vw * (calc(14 / var(--width_base))));
        padding-top: calc(100vw * (calc(9 / var(--width_base))));
        padding-bottom: calc(100vw * (calc(9 / var(--width_base))));
      }
    }
    & .menu__link.is-active {
      background-color: #80bc00;
    }

    & .sub-menu__item .menu__link {
      padding-left: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const handleClose = () => {
    menuEle.current.classList.remove("is-active")
    document.getElementsByTagName("header")[0].classList.remove("header-active")
    document.getElementsByTagName("main")[0].classList.remove("header-active")
  }

  return (
    <div css={menuCss} ref={menuEle}>
      <div css={headerCss}>
        {/* <Image imagen={logo} styleCss={logoCss} objectFit="contain"/> */}
        <Button eventEle={handleClose} typeEle="button">
          <Icon typeEle="svg-close" />
        </Button>
      </div>
      <ul css={listCss}>
        {listMenu &&
          listMenu.map(menuItem => (
            <MenuItem
              key={menuItem.id}
              menuItem={menuItem}
              wordPressUrl={wordPressUrl}
            />
          ))}
      </ul>
      <div className="text-center">
        <Button
          typeEle="link"
          link="/departamentos-venta/"
          styleEle={buttonCss}
          role="button"
        >
          Buscar Proyecto
        </Button>
      </div>
    </div>
  )
}
