import * as React from 'react';
import Slider from 'react-slick';
import CardProyecto from './CardProyecto';
import { css } from '@emotion/react';

export default function GrilllasItemSliderProyectos({
  dataProyectos,
  dataProximosProyectos,
}) {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="7px"
          points="35.86 3.5 4.95 34.41 34.51 63.97"
        />
      </svg>
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="7px"
          points="3.5 3.5 34.41 34.41 4.84 63.97"
        />
      </svg>
    </button>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: true,
    touchMove: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };

  const sliderCss = css`
    & .slick-arrow {
      z-index: 1;
      top: calc(100vw * (calc(190 / var(--width_base))));
    }
    & .slick-next {
      right: calc(100vw * (calc(10 / var(--width_base))));
    }

    & .slick-prev {
      left: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;

  return (
    <div>
      {(dataProyectos || dataProximosProyectos) && (
        <Slider {...settings} css={sliderCss}>
          {dataProyectos &&
            dataProyectos.map((proyecto, i) => {
              const estadoId = proyecto.estados?.nodes[0]?.databaseId;
              if (!(estadoId === 1365)) {
                return (
                  <div key={i}>
                    <CardProyecto data={proyecto} />
                  </div>
                );
              }
            })}
          {dataProximosProyectos &&
            dataProximosProyectos.map((proyecto, i) => {
              return (
                <div key={i}>
                  <CardProyecto data={proyecto} isProyecto={false} />
                </div>
              );
            })}
        </Slider>
      )}
    </div>
  );
}
