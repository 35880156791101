import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import BannerOthersPages from "../components/organisms/OthersPages/BannerOthersPages"
import ProyectosAvance from "../components/template/AvanceDeObra/ProyectosAvance"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri

        template {
          ... on Wordpress_Template_AvanceObras {
            avanceObras {
              tituloProyectos
              textoPortada

              proyectos {
                ... on Wordpress_ProximoProyecto {
                  id
                  uri
                  title
                  featuredImage {
                    node {
                      ...imagen
                    }
                  }
                  distritos {
                    nodes {
                      name
                    }
                  }
                  estados {
                    nodes {
                      name
                      slug
                    }
                  }
                  dormitorios {
                    nodes {
                      name
                    }
                  }
                  template {
                    ... on Wordpress_Template_ProximoProyecto {
                      templateName
                      proximoProyecto {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                        lanzamientoDescripcion
                        avanceDeObraAvance
                        entregaEstimadaAvance
                        galeriaAvance {
                          imagen {
                            ...imagen
                          }
                        }
                      }
                    }
                  }
                }
                ... on Wordpress_Proyecto {
                  id
                  title
                  uri
                  featuredImage {
                    node {
                      ...imagen
                    }
                  }
                  distritos {
                    nodes {
                      name
                    }
                  }
                  estados {
                    nodes {
                      name
                      slug
                    }
                  }
                  dormitorios {
                    nodes {
                      name
                    }
                  }
                  template {
                    ... on Wordpress_Template_ProyectoEnVenta {
                      templateName
                      proyectoEnVenta {
                        logoPortada {
                          ...imagen
                        }
                        direccionDescripcion
                        lanzamientoDescripcion
                        entregaEstimadaAvance
                        fechaAvance
                        avanceDeObraAvance
                        galeriaAvance {
                          imagen {
                            ...imagen
                          }
                        }
                      }
                    }
                  }
                }
              }

              imagenPortada {
                ...imagen
              }
              imagenMovilPortada {
                ...imagen
              }
            }
          }
        }

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function AvanceObras({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.avanceObras
  const proyectos = template?.proyectos

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <BannerOthersPages
        title={template?.textoPortada}
        imagen={template?.imagenPortada}
        titlePage={page.title}
      />
      <ProyectosAvance
        data={proyectos}
        title={template?.tituloProyectos}
        titlePage={page.title}
      />
    </LayoutGeneral>
  )
}

