import * as React from "react"
import DormitorioProyecto from "../../template/Proyecto/DormitorioProyecto"
import { css } from "@emotion/react"

export default function BotonesProyecto ({data, nroDorm,setCodInmueble, setModelo, handleEventButton}) {

  const handleTab = (e,dataSlider) => {
    const first = dataSlider[0];
    const tabsButton = document.querySelectorAll(".tabs-button-proyecto");
    tabsButton.forEach( (ele) => ele.classList.contains("is-active") && ele.classList.remove("is-active"))
    let tabsPanel = document.querySelectorAll(".tabs-panel-proyecto")
    tabsPanel.forEach((ele) => ele.classList.contains("is-active") && ele.classList.remove("is-active"))
    e.target.classList.add("is-active")
      document.getElementById(e.target.dataset.id).classList.add("is-active");
      setCodInmueble(first.codInmueble);
      setModelo(first.modelo);
  }

  const tabCss = css`
    //width: ca;
    padding: calc(100vw * (calc(10 / var(--width_base)))) calc(100vw * (calc(17 / var(--width_base))));
    font-size: calc(100vw * (calc(19 / var(--width_base))));
    line-height: calc(100vw * (calc(19 / var(--width_base))));
    color: white;
    background-color: #4A4A49;
    
    @media (max-width: 767px) {
      width: auto;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
      padding-top: calc(100vw * (calc(13 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(13 / var(--width_base))));
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
      margin-left: calc(100vw * (calc(5 / var(--width_base))));
      margin-right: calc(100vw * (calc(5 / var(--width_base))));
    }
    &.is-active{
      background-color: #93BA1F;
    }
    & *{
      pointer-events: none;
    }
    
    &:not(:last-of-type){
      @media (min-width: 768px) {
        margin-right: calc(100vw * (calc(29 / var(--width_base))));             
      }
    }
  `;

  const tabList = css`
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: initial;
    }
  `;
  const fullList = css`
    width: 100%;
  `;

  const tabPannel = css`
    display: none;
    padding-top: calc(100vw * (calc(20 / var(--width_base))));
    padding-left: calc(100vw * (calc(20 / var(--width_base))));
    padding-right: calc(100vw * (calc(20 / var(--width_base))));
    &.is-active{
      display: block;
    }
    @media (max-width: 767px) {
      padding-left: initial;
      padding-right: initial;
      padding-top: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  return(
    <div css={fullList}>
      {
        (nroDorm && data) &&
        <>
          <div css={tabList}>
            {
              nroDorm && nroDorm.map((nro,i)=>(
                <button css={tabCss} type="button" key={i} data-id={`tabs-panel-proyecto-${i}`} onClick={(e) => handleTab(e,data[i])} className={`tabs-button-proyecto ${i === 0 ? "is-active" :""}`}>{nro} Dormitorio{(nro >= 2) && 's'}</button>
              ))
            }
          </div>
          {
            data && data.map((dorm,i)=>(
              <div key={i} css={tabPannel} className={`tabs-panel-proyecto ${i === 0 ? "is-active" :""}`} id={`tabs-panel-proyecto-${i}`}>
                <DormitorioProyecto data={dorm} eventEle={handleEventButton} setCodInmueble={setCodInmueble} setModelo={setModelo}/>
              </div>
            ))
          }
        </>
      }
    </div>
  )
}
