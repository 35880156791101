import React from 'react';
import { css } from '@emotion/react';
import Image from '../../atoms/Image/Image';
import { Link } from 'gatsby';

export default function SliderItemStyle4({ data }) {
  const estile4ImageCss = css`
    width: 100%;
    min-height: calc(100vw * (500 / var(--width_base)));
    height: 95vh;
    object-position: bottom !important;
  `;

  const style4Overlay = css`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    width: 100%;
    width: calc(100vw * (1250 / var(--width_base)));
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      width: 100%;
      justify-content: center;
    }
  `;

  const style4ImageMainContainer = css`
    display: flex;
    width: calc(100vw * (500 / var(--width_base)));
    @media (max-width: 767px) {
      width: calc(100vw * (200 / var(--width_base)));
    }
  `;

  const style4ImageMain = css`
    width: calc(100vw * (500 / var(--width_base)));
    @media (max-width: 767px) {
      width: calc(100vw * (200 / var(--width_base)));
    }
  `;

  const imageContentCss = css``;

  const sliderItemCss = css`
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: calc(100vw * (250 / var(--width_base)));
      left: 0;
      right: 0;
      pointer-events: none;
      bottom: 0;
      z-index: 1;
      background: -moz-linear-gradient(
        50% -15.24% -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-gradient(
        linear,
        50% -15.24%,
        50% 104.78%,
        color-stop(0, rgba(255, 255, 255, 0)),
        color-stop(1, rgba(0, 0, 0, 1))
      );
      background: -o-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -ms-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#000000' ,GradientType=0)";
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=0);
      pointer-events: none;

      @media (max-width: 767px) {
        content: none;
      }
    }
  `;

  return (
    <div css={sliderItemCss} className="slider-carusel__item">
      <div>
        <div className="only-desk">
          {data?.estilo4ImagenFondoDesktop && (
            <div css={imageContentCss} className="slider-image-full">
              <Link
                to={data?.url === '#' ? '/' : data?.url}
                className="img-pointer-none block"
                aria-label={data?.estilo4ImagenFondoDesktop?.title}
                tabIndex="-1"
              >
                <Image
                  styleCss={estile4ImageCss}
                  imagen={data?.estilo4ImagenFondoDesktop}
                  classEle="slider-carusel__imagen slider-image-full"
                />
                <div css={style4Overlay}>
                  <Image
                    imagen={data?.estilo4ImagenPrincipalDesktop}
                    styleContentCss={style4ImageMainContainer}
                    styleCss={style4ImageMain}
                    objectFit="contain"
                  />
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="only-mov">
          {data?.estilo4ImagenFondoMobile && (
            <div css={imageContentCss} className="slider-image-full">
              <Link
                to={data?.url === '#' ? '/' : data?.url}
                className="img-pointer-none block"
                aria-label={data?.estilo4ImagenFondoMobile?.title}
                tabIndex="-1"
              >
                <Image
                  styleCss={estile4ImageCss}
                  imagen={data?.estilo4ImagenFondoMobile}
                  classEle="slider-carusel__imagen slider-image-full"
                />
                <div css={style4Overlay}>
                  <Image
                    imagen={data?.estilo4ImagenPrincipalMobile}
                    styleContentCss={style4ImageMainContainer}
                    styleCss={style4ImageMain}
                    objectFit="contain"
                  />
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
