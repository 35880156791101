import * as React from "react"
import Text from "../../atoms/Text/Text"
import CotizadorProyecto from "../../organisms/Proyectos/CotizadorProyecto"
import { css } from "@emotion/react"
import convertStringAObj from "../../../../helper/convertStringAObj"

export default function CotizarProyecto ({siteUrl,title, idProyecto, proyecto, titlePage, correoEvolta, paginaGracias, quitarInmuebles}) {

  const titleCss = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(35 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
    & p{
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(21 / var(--width_base))));
      line-height: calc(100vw * (calc(27 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
      color: #999;
    }
  `;

  const sectionCss = css`
    background-color: #5E5E5E;
    padding-top: calc(100vw * (calc(45 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
    overflow: hidden;
    min-height: calc(100vw * (calc(750 / var(--width_base))));
    font-family: "Montserrat Regular",sans-serif;
    @media (max-width: 767px) {
      background-color: #444443;
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      padding-top: calc(100vw * (calc(35 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(35 / var(--width_base))));
      min-height: auto;
    }
  `;

  const contentCss = css`
    max-width: calc(100vw * (calc(1332 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  `;

  const arrNoInmuebles = convertStringAObj(quitarInmuebles);

  return(
    <section css={sectionCss} id="cotizar">
      {titlePage && <Text classEle="d-none" data={`Cotizar ${titlePage}`} typeEle="h2"/>}
      <div css={contentCss}>
        <Text data={title ? title : `<p>COTIZADOR DE <span style="color: #93ba1f;">DEPARTAMENTOS</span></p>`} align="center" styleCss={titleCss} colorText="white" data-aos="fade-right" data-aos-delay="200"/>
        <CotizadorProyecto
          siteUrl={siteUrl}
          idProyecto={idProyecto}
          proyecto={proyecto}
          correoEvolta={correoEvolta}
          paginaGracias={paginaGracias}
          arrNoInmuebles={arrNoInmuebles}
        />
      </div>
    </section>
  )
}