import * as React from "react"
import Text from "../../atoms/Text/Text"
import Image from "../../atoms/Image/Image"
import { css } from "@emotion/react"

export default function NuestraInicio ({data, title, titlePage, styleCss}) {

  const contentCss = css`
    max-width: calc(100vw * (calc(1024 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(100vw * (calc(55 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(15 / var(--width_base))));
    color: white;
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(30 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const cardsCss = css`
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;          
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(23 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
    @media (max-width: 767px) {
      color: #999;          
    }
  `;

  const cardCss = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    &:not(:last-of-type){
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }

    &:nth-of-type(2) .card-imagen{
      @media (max-width: 767px) {
        max-width: calc(100vw * (calc(90 / var(--width_base))));         
      }
    }
    
  `;

  const cardImageCss = css`
    height: calc(100vw * (calc(63 / var(--width_base))));
    @media (max-width: 767px) {
      width: calc(100vw * (calc(150 / var(--width_base))));
      margin-left: auto;
      margin-right: auto;
    }
  `;

  const cardNumCss = css`
    font-size: calc(100vw * (calc(33 / var(--width_base))));
    line-height: calc(100vw * (calc(33 / var(--width_base))));
    font-family: "Montserrat Bold",sans-serif;
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    min-height: calc(100vw * (calc(33 / var(--width_base))));
  `;

  const cardTextCss = css`
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      text-align: center;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(19 / var(--width_base))));
      color: #999;
    }
  `;

  const sectionCss = css`
    background-color: #5E5E5E;
    @media (max-width: 767px) {
      background-color: #444443;
    }
    ${styleCss}
  `;

  return(
    <section css={sectionCss}>
      {titlePage && <Text classEle="d-none" data={`Nuestra experiencia ${titlePage}`} typeEle="h2"/>}
      <div css={contentCss}>
        <Text typeEle="h2" data={title} styleCss={titleCss} align="center" data-aos="fade-right" data-aos-delay="200"/>
        <div css={cardsCss}>
          {
            data && data.map((data, i)=>(
              <div css={cardCss}>
                {
                  data.imagen &&
                  <Image imagen={data.imagen} objectFit="contain" styleCss={cardImageCss} classEle="card-imagen"/>
                }
                <Text data={data?.numero ? data?.numero : "&nbsp;"} styleCss={cardNumCss} classEle={data?.numero ? "": "only-desk"}/>
                {
                  data?.texto &&
                  <Text data={data?.texto} styleCss={cardTextCss} data-aos={i=== 0 ? "fade-right": (i === 1 ? "fade-right" : "fade-left")} data-aos-delay={i=== 0 ? "200": (i === 1 ? "300" : "400")}  />
                }
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}
