import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import CardProyecto from '../../organisms/Proyectos/CardProyecto';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

export default function SliderProyectos({ settings, idProyecto, styleEleCss }) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        proyectos(first: 40) {
          nodes {
            databaseId
            title
            uri
            featuredImage {
              node {
                ...imagen
              }
            }
            distritos {
              nodes {
                name
              }
            }
            template {
              ... on Wordpress_Template_ProyectoEnVenta {
                proyectoEnVenta {
                  logoPortada {
                    ...imagen
                  }
                  direccionDescripcion
                  lanzamientoDescripcion
                }
              }
              ... on Wordpress_Template_ProximoProyecto {
                proximoProyecto {
                  logoPortada {
                    ...imagen
                  }
                  direccionDescripcion
                }
              }
            }
            estados {
              nodes {
                name
                slug
              }
            }
            dormitorios {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `);

  const proyectos = data?.wordpress?.proyectos?.nodes;
  const [proyectosMostrar, setProyectosMostrar] = useState([]);
  useEffect(() => {
    let newProyectos = [...proyectos];
    newProyectos = newProyectos.filter(
      (proyectoM) =>
        proyectoM.databaseId !== idProyecto &&
        proyectoM.estados.nodes[0].slug !== 'entregado'
    );
    setProyectosMostrar(newProyectos);
    return () => {
      setProyectosMostrar(proyectos);
    };
  }, [proyectos, idProyecto]);

  const sliderItemCss = css`
    padding-left: calc(100vw * (calc(7 / var(--width_base))));
    padding-right: calc(100vw * (calc(7 / var(--width_base))));
  `;

  return (
    <Slider {...settings} css={styleEleCss}>
      {proyectosMostrar &&
        proyectosMostrar.map((proyecto, i) => (
          <div key={i} css={sliderItemCss}>
            <CardProyecto data={proyecto} />
          </div>
        ))}
    </Slider>
  );
}
