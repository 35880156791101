import * as React from 'react';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import Image from '../../atoms/Image/Image';
import { css } from '@emotion/react';
import { Link } from 'gatsby';

export default function NavegationHeader({
  logo,
  logoOscuro,
  cel,
  wsp,
  cotizar,
  menuEle,
}) {
  const logoCss = css`
    width: calc(100vw * (calc(180 / var(--width_base))));
    padding-top: calc(100vw * (calc(10 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    @media (max-width: 1366px) {
      width: calc(100vw * (calc(200 / 1366)));
    }
    @media (max-width: 767px) {
      width: calc(100vw * (calc(80 / var(--width_base))));
    }
  `;

  const btnProyectCss = css`
    font-family: 'Montserrat Bold', sans-serif;
    background-color: #93ba1f;
    color: white;
    text-transform: uppercase;
    font-size: calc(100vw * (calc(11 / var(--width_base))));
    padding: calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(15 / var(--width_base))));
    text-align: center;

    @media (max-width: 767px) {
      padding: calc(100vw * (calc(5 / var(--width_base))))
        calc(100vw * (calc(7 / var(--width_base))));
      margin-left: calc(100vw * (calc(20 / var(--width_base))));
      font-size: calc(100vw * (calc(8 / var(--width_base))));
    }
  `;

  const groupBtnsCss = css`
    display: flex;
    align-items: center;
    gap: calc(100vw * (calc(20 / var(--width_base))));

    @media (max-width: 767px) {
      gap: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;

  const navCss = css`
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 767px) {
      justify-content: space-between;
    }
  `;

  const textCelCss = css`
    font-family: 'Montserrat Bold', sans-serif;
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(25 / var(--width_base))));
    padding-left: calc(100vw * (calc(9 / var(--width_base))));
    padding-right: calc(100vw * (calc(17 / var(--width_base))));
    margin-right: calc(100vw * (calc(9 / var(--width_base))));
    // border-right: calc(100vw * (calc(1 / var(--width_base)))) solid black;
    color: #4a4a49;
  `;

  const navColumn = css`
    width: 33.33%;
    //display: inline-flex;
    //align-items: center;
    @media (max-width: 767px) {
      width: auto;
    }
  `;

  const cotizarCss = css`
    background-color: #93ba1f;
    color: white;
    font-size: calc(100vw * (calc(11 / var(--width_base))));
    line-height: calc(100vw * (calc(11 / var(--width_base))));
    padding: calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(17 / var(--width_base))));
    text-transform: uppercase;
    margin-right: calc(100vw * (calc(38 / var(--width_base))));
    position: relative;
    font-family: 'Montserrat Bold', sans-serif;
    &:before {
      content: '';
      display: block;
      background-color: black;
      height: calc(100vw * (calc(31 / var(--width_base))));
      width: calc(100vw * (calc(1 / var(--width_base))));
      position: absolute;
      right: calc(100vw * (calc(-23 / var(--width_base))));
      top: 0;
      bottom: 0;
    }
  `;
  const navColumnButtonCss = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `;

  const handleOpen = () => {
    if (
      document
        .getElementsByTagName('header')[0]
        .classList.contains('header-active') === false
    ) {
      menuEle.current.classList.add('is-active');
      document.getElementsByTagName('header')[0].classList.add('header-active');
      document.getElementsByTagName('main')[0].classList.add('header-active');
      /*document.getElementsByClassName('ham-1').classList.add('rotate-1-x');*/
    } else {
      menuEle.current.classList.remove('is-active');
      document
        .getElementsByTagName('header')[0]
        .classList.remove('header-active');
      document
        .getElementsByTagName('main')[0]
        .classList.remove('header-active');
      /*document.getElementsByClassName('ham-1').classList.remove('rotate-1-x');*/
    }
  };

  return (
    <nav css={navCss}>
      <div className="align-center" css={navColumn}>
        <Button typeEle="linkTarget" link={`tel:${cel}`} classEle="only-desk">
          <Icon typeEle="phone" />
          <span css={textCelCss}>{cel}</span>
        </Button>
        <Button typeEle="linkTarget" link={`tel:${cel}`} classEle="only-mov">
          <Icon typeEle="phone2" />
        </Button>
        {/* <Button typeEle="linkTarget" link={wsp} classEle="only-desk">
          <Icon typeEle="whatsapp"/>
        </Button> */}
      </div>
      <div className="text-center" css={navColumn}>
        <Button typeEle="link" link="/" role="button">
          <Image
            imagen={logo}
            styleCss={logoCss}
            objectFit="contain"
            classEle="only-desk"
          />
          <Image
            imagen={logoOscuro}
            styleCss={logoCss}
            objectFit="contain"
            classEle="only-mov"
          />
        </Button>
      </div>
      <div className="text-right" css={navColumn}>
        <div css={navColumnButtonCss}>
          {cotizar && (
            <Button
              typeEle="cotizar"
              classEle="only-desk"
              styleEle={cotizarCss}
              role="button"
            >
              Cotiza Aquí
            </Button>
          )}
          <div css={groupBtnsCss}>
            {/* <Link */}
            {/*   className="only-mov" */}
            {/*   href={`/departamentos-venta/`} */}
            {/*   css={btnProyectCss} */}
            {/* > */}
            {/*   Venta */}
            {/* </Link> */}
            <Link href={`/departamentos-venta/`} css={btnProyectCss}>
              Proyectos en venta
            </Link>
            <Button
              typeEle="button"
              eventEle={handleOpen}
              role="button"
              aria-label="button"
            >
              <Icon typeEle="menu" />
              <Icon typeEle="close" />
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
}
