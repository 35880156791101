import React from "react"
import MenuItem from "../MenuItem/MenuItem";

export default function HijosMenuItem ({menuItems, wordPressUrl}){

    return (
       <ul className="sub-menu">
          {
              menuItems.edges && menuItems.edges.map((menuItem,i)=>(
                  <MenuItem
                      key={i}
                      menuItem={menuItem.node}
                      wordPressUrl={wordPressUrl}
                      classEle="sub-menu__item"
                  />
              ))
          }
       </ul>
    )
};