import * as React from "react"
import Text from "../../atoms/Text/Text"
import { css } from "@emotion/react"
import FormularioProyectoForm from "../../organisms/Form/FormularioProyectoForm"

export default function FormularioProyecto ({idFormulario, titlePage, siteUrl, paginaGracias}) {

  const sectionCss = css`
    background-color: #5E5E5E;
    padding-top: calc(100vw * (calc(45 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
    overflow: hidden;
    font-family: "Montserrat Regular",sans-serif;
    @media (max-width: 767px) {
      background-color: #444443;
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      padding-top: calc(100vw * (calc(35 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(35 / var(--width_base))));
      min-height: auto;
    }
  `;

  const contentCss = css`
    /* max-width: calc(100vw * (calc(992 / var(--width_base)))); */
    max-width: calc(100vw * (calc(592 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      max-width: 100%;          
    }
  `;

  return(
    <section css={sectionCss} id="cotizar">
        <Text classEle="d-none" data={`Formulario ${titlePage}`} typeEle="h2"/>
        <div css={contentCss}>
            <FormularioProyectoForm
                nomProyecto={titlePage} 
                idForm={idFormulario}
                siteUrl={siteUrl}
                paginaGracias={paginaGracias}
            />
        </div>
    </section>
  )
}