import React from 'react';
import Pagination from "../../organisms/Pagination/Pagination"
import PostEntry from "../../organisms/PostEntry/PostEntry"
import { css } from '@emotion/react';

export default function GridBlog({posts,pageNumber, hasNextPage, postsPerPage, total}) {

  const sectionCss = css`
  background-color: #5e5e5e;
  padding-top: calc(100vw * (calc(45 / var(--width_base))));
  padding-right: calc(100vw * (calc(103 / var(--width_base))));
  padding-left: calc(100vw * (calc(123 / var(--width_base))));
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 767px) {
  padding-right: 0;
  padding-left: 0;
    }
`

    const section2Css = css`
  background-color: #5e5e5e;
  padding-right: calc(100vw * (calc(103 / var(--width_base))));
  padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
  padding-left: calc(100vw * (calc(123 / var(--width_base))));
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 767px) {
  padding-right: 0;
  padding-left: 0;
    }
`
  
  return (
    <div>
        <div css={sectionCss}>
        {posts && posts.map((post, i) =>(
                <div key={i}>
                  <PostEntry
                    link={`/blog${post?.uri}`}
                    data={post}
                  />
                </div>
              ))}
        </div>
        <div css={section2Css}>
          <Pagination
          pageNumber={pageNumber}
          hasNextPage={hasNextPage}
          total={total}
          itemsPerPage={postsPerPage}
          postsPath="/blog"
          paginationPrefix="page"
        />
        </div>
    </div>
  );

}
