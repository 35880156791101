import * as React from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlideItem from "../../moleculas/SlideItem/SlideItem"
import SliderItemSomos from "../../moleculas/SlideItem/SliderItemSomos"
import SliderItemBanner from "../../moleculas/SlideItem/SliderItemBanner"
import SliderItemCotizar from "../../moleculas/SlideItem/SliderItemCotizar"

export default function SliderCarousel (props) {

  const {settings,index, styleCss, data, typeStyle, relEle, eventEle,fancybox} = props;
  
  return(
    <Slider {...settings} css={styleCss} ref={relEle}>
      {
        data &&
        data.map((dato, i)=>(
          <>
            {
              (typeStyle === "somos") &&
                <SliderItemSomos keyEle={i} data={dato}/>
            }
            {
              (typeStyle === "banner-inicio") &&
              <SliderItemBanner keyEle={i} data={dato}/>
            }
            {
              (typeStyle === "cotizar") &&
              <SliderItemCotizar keyEle={i} data={dato} eventEle={eventEle}/>
            }
            {
              (typeStyle !== "banner-inicio" && typeStyle !== "somos") &&
              <SlideItem keyEle={i} data={dato} typeStyle={typeStyle} fancybox={fancybox} index={index}/>
            }
          </>
        ))
      }
    </Slider>
  )
}