import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { css } from "@emotion/react"
import LibroForm from "../components/organisms/Form/LibroForm"
import Text from "../components/atoms/Text/Text"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function Libro({ data }) {
  const page = data?.wordpress?.page
  // const template = page?.template?.tour;

  const titleCss = css`
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    padding-left: calc(100vw * (calc(5 / var(--width_base))));
    padding-right: calc(100vw * (calc(5 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
    }
  `

  const sectionCss = css`
    background-color: #4a4a49;
  `

  const contentCss = css`
    padding-top: calc(100vw * (calc(50 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
    max-width: calc(100vw * (calc(1000 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
  `

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <section css={sectionCss}>
        <div css={contentCss}>
          <Text
            data={page.title}
            styleCss={titleCss}
            colorText="white"
            data-aos="fade-right"
            data-aos-delay="200"
          />
          <LibroForm />
        </div>
      </section>
    </LayoutGeneral>
  )
}

