import * as React from "react"
import { css } from "@emotion/react";
import BeneficiosForm from "../../organisms/Form/BeneficiosForm"
import Text from "../../atoms/Text/Text"

export default function FormularioBeneficios ({title, titlePage}) {

  const contentCss = css`
    padding-top: calc(100vw * (calc(55 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(47 / var(--width_base))));
    max-width: calc(100vw * (calc(685 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(30 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
      max-width: initial;
    }
  `;
  
  return(
    <section css={css`background-color: #4A4A49`}>
      {titlePage && <Text classEle="d-none" data={`Formulario ${titlePage}`} typeEle="h2"/>}
      <div css={contentCss}>
        <BeneficiosForm/>
      </div>
    </section>
  )
}
