import React from "react"
import Text from "../../atoms/Text/Text"
import getFecha from "../../atoms/Utilities/Fecha"
import getCategories from "../../atoms/Utilities/Categories"
import { css } from "@emotion/react"
import Button from "../../atoms/Button/Button"
import Icon from "../../atoms/Icon/Icon"
import { useLocation } from "@reach/router"

export default function GridBlogDetalle({ post }) {
  const dateCss = css`
    font-weight: 400;
    margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    text-align: right;
    color: white;
    font-family: "Montserrat Regular", sans-serif;
  `
  const categoryCss = css`
    font-weight: 400;
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    color: #61a41d;
  `
  const titleCss = css`
    font-weight: bold;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    font-size: calc(100vw * (calc(28 / var(--width_base))));
    line-height: calc(100vw * (calc(28 / var(--width_base))));
    color: white;
    font-family: "Montserrat Regular", sans-serif;
  `
  const textCss = css`
    font-weight: 400;
    margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    color: white;
    font-family: "Montserrat Regular", sans-serif;
    text-align: justify;
    & img {
      /* 655 x 960 */
      object-fit: cover;
      max-height: calc(100vw * (calc(800 / var(--width_base))));
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: calc(100vw * (calc(900 / var(--width_base))));
    }
      table{
        border-spacing: 0px;
        td{
          border: white 1px solid;
        }
      }
  `
  const cardCss = css`
    background-color: #4a4a49;
    padding-top: calc(100vw * (calc(38 / var(--width_base))));
    padding-right: calc(100vw * (calc(143 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(56 / var(--width_base))));
    padding-left: calc(100vw * (calc(143 / var(--width_base))));

    img {
      margin-top: calc(100vw * (calc(30 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }

    @media (max-width: 767px) {
      padding-right: calc(100vw * (calc(30 / var(--width_base))));
      padding-left: calc(100vw * (calc(30 / var(--width_base))));
    }
  `
  const buttonShareCss = css`
    margin-top: 0 !important;
    display: flex !important;
    margin-left: auto;
    color: white;
    background-color: #93ba1f;
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    min-width: calc(100vw * (calc(230 / var(--width_base))));
    min-height: calc(100vw * (calc(40 / var(--width_base))));
    margin-top: calc(100vw * (calc(69 / var(--width_base))));
    justify-content: center;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
      min-height: calc(100vw * (calc(42 / var(--width_base))));
      min-width: calc(100vw * (calc(150 / var(--width_base))));
      margin-top: calc(100vw * (calc(30 / var(--width_base))));
    }
  `
  const linkCss = css`
    display: block;
    margin-left: auto;
  `
  const location = useLocation()
  const link =
    "https://www.facebook.com/sharer/sharer.php?u=" +
    location.href +
    "&t=" +
    post.title
  return (
    <div css={cardCss}>
      <Text
        styleCss={dateCss}
        data={`${getFecha(post?.modified, "detalle")}`}
      />
      <Text
        styleCss={categoryCss}
        data={getCategories(post?.categories.edges, "single")}
        type="content"
      />
      <Text styleCss={titleCss} data={post?.title} type="content" />
      <Text
        styleCss={textCss}
        data={post?.entrada.textoCompleto}
        type="content"
      />
      {/* <a css={linkCss} href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.coopacsanmiguel.pe%2Fcomo-llegar-a-fin-de-mes-sin-problemas-finanzas-tips-para-lograr-tus-metas-de-ahorro%2F&t=%C2%BFC%C3%B3mo%20llegar%20a%3Cbr%3E%20fin%20de%20mes%20sin%20problemas%3F">
      <Button typeEle="button" styleEle={buttonShareCss}>
        <Text data={"COMPARTIR"} />
        <Icon typeEle="svg-share" />
      </Button>
      </a> */}
      <a css={linkCss} href={link}>
        <Button typeEle="button" styleEle={buttonShareCss}>
          <Text data={"COMPARTIR"} />
          <Icon typeEle="svg-share" />
        </Button>
      </a>
    </div>
  )
}
