import * as React from "react"
import { css } from "@emotion/react"
import Image from "../../atoms/Image/Image"
import Text from "../../atoms/Text/Text"

export default function CardsFundamentaMas({ data, titlePage }) {
  const sectionCss = css`
    background-color: #444443;
    padding-bottom: calc(100vw * (calc(58 / var(--width_base))));
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(60 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const contentCss = css`
    max-width: calc(100vw * (calc(864 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `

  const textCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-top: calc(100vw * (calc(33 / var(--width_base))));

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(22 / var(--width_base))));
      margin-top: calc(100vw * (calc(25 / var(--width_base))));
      max-width: calc(100vw * (calc(280 / var(--width_base))));
      margin-left: auto;
      margin-right: auto;
    }
    & br {
      @media (max-width: 767px) {
        display: none;
      }
    }
  `

  const imageCss = css`
    height: calc(100vw * (calc(102 / var(--width_base))));
    @media (max-width: 767px) {
      height: calc(100vw * (calc(70 / var(--width_base))));
    }
  `

  const cardCss = css`
    text-align: center;
    &:not(:last-of-type) {
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
      }
    }
  `

  return (
    <section css={sectionCss}>
      {titlePage && (
        <Text classEle="d-none" data={`Cards ${titlePage}`} typeEle="h2" />
      )}
      <div css={contentCss}>
        {data &&
          data.map((dato, i) => (
            <div key={i} css={cardCss}>
              <Image
                imagen={dato?.imagen}
                styleCss={imageCss}
                objectFit="contain"
              />
              <Text
                data={dato?.texto}
                styleCss={textCss}
                colorText="#949494"
                align="center"
                data-aos={
                  i === 0 ? "fade-right" : i === 1 ? "fade-right" : "fade-left"
                }
                data-aos-delay={i === 0 ? "200" : i === 1 ? "300" : "400"}
              />
            </div>
          ))}
      </div>
    </section>
  )
}
