import * as React from "react"
import { useEffect } from "react"
import Seo from "../components/atoms/Seo/Seo"
import LayoutSinNavegacion from "../components/organisms/Layout/LayoutSinNavegacion"
import { css } from "@emotion/react"

export default function Pdf() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const redirects = [
        {
          value:
            "aHR0cHM6Ly9hcHJvcy1xYS5uZXQucGUvZnVuZGFtZW50YS93cC1jb250ZW50L3VwbG9hZHMvMjAyMS8xMC9FY28tRGVyYnktQnJvY2h1cmUtZGlnaXRhbC0wNjIwLnBkZg==",
          newUrl: "/pdf/brochure-digital-departamentos-monterrico-eco-derby/",
        },
        {
          value:
            "aHR0cHM6Ly9hcHJvcy1xYS5uZXQucGUvZnVuZGFtZW50YS93cC1jb250ZW50L3VwbG9hZHMvMjAyMS8xMC9CUk9DSFVSRS1ESUdJVEFMLUVDTy1ERVNJR04tMTMtRElDLnBkZg==",
          newUrl: "/pdf/brochure-digital-departamentos-san-isidro-eco-design/",
        },
        {
          value:
            "aHR0cHM6Ly9hcHJvcy1xYS5uZXQucGUvZnVuZGFtZW50YS93cC1jb250ZW50L3VwbG9hZHMvMjAyMS8xMC9CUk9DSFVSRS1ESUdJVEFMLnBkZg==",
          newUrl: "/pdf/brochure-departamentos-san-isidro-basadre1310/",
        },
        {
          value:
            "aHR0cHM6Ly9hcHJvcy1xYS5uZXQucGUvZnVuZGFtZW50YS93cC1jb250ZW50L3VwbG9hZHMvMjAyMS8xMC9Ccm9jaHVyZS1FY28tUHJhZG8ucGRm",
          newUrl: "/pdf/brochure-departamentos-miraflores-eco-prado/",
        },
        {
          value:
            "aHR0cHM6Ly9hcHJvcy1xYS5uZXQucGUvZnVuZGFtZW50YS93cC1jb250ZW50L3VwbG9hZHMvMjAyMi8wMy9Ccm9jaHVyZS1kaWdpdGFsX2Vjby1zdHlsZS5wZGY=",
          newUrl: "/pdf/brochure-departamentos-magdalena-eco-style/",
        },
        {
          value:
            "aHR0cHM6Ly9hcHJvcy1xYS5uZXQucGUvZnVuZGFtZW50YS93cC1jb250ZW50L3VwbG9hZHMvMjAyMS8xMC9CUk9DSFVSRS1ESUdJVEFMLUVDTy0yOC5wZGY=",
          newUrl: "/pdf/brochure-departamentos-miraflores-eco28/",
        },
      ]
      const urlParams = new URLSearchParams(window.location.search)
      const url = urlParams.get("url")
      if (!url) window.location.href = "/"
      const item = redirects.find(item => item.value === url)
      window.location.href = item?.newUrl
    }
  }, [])

  const linkCss = css`
    display: none;
  `

  return (
    <LayoutSinNavegacion title="Pdf" slug="/pdf/">
      <a css={linkCss} href="/">
        Inicio
      </a>
      <Seo title="Pdf" />
    </LayoutSinNavegacion>
  )
}
