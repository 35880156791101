import * as React from "react"
import SliderCarousel from "../../organisms/SliderCarousel/SliderCarousel"
import { css } from "@emotion/react"
import { useRef } from "react"
import SelectProyecto from "../../organisms/Proyectos/SelectProyecto"

export default function DormitorioProyecto ({data, eventEle, setCodInmueble, setModelo}) {

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
    adaptiveHeight: false,
    fade: true,
    lazyLoad: true,
  }

  const sectionCss = css`
    max-width: calc(100vw * (calc(640 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    // border: 2px solid red;
    width: 100%;
    @media (max-width: 767px) {
      max-width: initial;     
    }
  `;

  const sliderEle = useRef(null);

  const sliderCss = css`
    & img{
      min-height: calc(100vw * (calc(200 / var(--width_base))));
      width: 100%;
      @media (max-width: 767px) {
        min-height: calc(100vw * (calc(100 / var(--width_base))));          
      }
    }
  `;

  return(
    <div css={sectionCss}>
      <SelectProyecto data={data} setCodInmueble={setCodInmueble} setModelo={setModelo} slider={sliderEle}/>
      {
        data &&
        <>
          <SliderCarousel
            data={data}
            settings={settings}
            typeStyle="cotizar"
            relEle={sliderEle}
            styleCss={sliderCss}
            eventEle={eventEle}
          />
        </>

      }
    </div>
  )
}
