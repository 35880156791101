import * as React from 'react';
import { useState } from 'react';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/Text/Text';
import Icon from '../../atoms/Icon/Icon';
import { css } from '@emotion/react';

export default function CardVentaProyecto({ keyEle, data }) {
  const [show, setShow] = useState(false);

  const imagenCss = css`
    width: calc(100vw * (calc(129 / var(--width_base))));
    height: calc(100vw * (calc(129 / var(--width_base))));
    border-radius: 50%;
    & img,
    & .gatsby-image-wrapper {
      object-position: top center !important;
    }

    @media (max-width: 767px) {
      width: calc(100vw * (calc(89 / var(--width_base))));
      height: calc(100vw * (calc(89 / var(--width_base))));
    }
  `;

  const cardCss = css`
    &:not(:last-of-type) {
      margin-bottom: calc(100vw * (calc(37 / var(--width_base))));
    }
  `;

  const cardImagenCss = css`
    ${imagenCss};
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
  `;

  const iconosCss = css`
    position: absolute;
    z-index: 1;
    border-radius: 50%;
  `;

  const mailCss = css`
    ${iconosCss};
    right: calc(100vw * (calc(-35 / var(--width_base))));
    bottom: calc(100vw * (calc(45 / var(--width_base))));
    @media (max-width: 767px) {
      right: calc(100vw * (calc(-28 / var(--width_base))));
      bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const phoneCss = css`
    ${iconosCss};
    right: calc(100vw * (calc(-18 / var(--width_base))));
    cursor: pointer;
    bottom: calc(100vw * (calc(-5 / var(--width_base))));
  `;

  const nombreCss = css`
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(14 / var(--width_base))));
    @media (max-width: 767px) {
      color: white;
      font-size: calc(100vw * (calc(11 / var(--width_base))));
      line-height: calc(100vw * (calc(11 / var(--width_base))));
    }
  `;

  const nombre2Css = css`
    font-weight: 700;
    margin-top: calc(100vw * (calc(15 / var(--width_base))));
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(28 / var(--width_base))));
    @media (max-width: 767px) {
      color: white;
      font-size: calc(100vw * (calc(11 / var(--width_base))));
      line-height: calc(100vw * (calc(11 / var(--width_base))));
    }
  `;

  const cargoCss = css`
    font-size: calc(100vw * (calc(15 / var(--width_base))));
    line-height: calc(100vw * (calc(15 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(7 / var(--width_base))));
    @media (max-width: 767px) {
      color: white;
      font-size: calc(100vw * (calc(11 / var(--width_base))));
      line-height: calc(100vw * (calc(11 / var(--width_base))));
    }
  `;
  const iconoWhatsappCss = css`
    /* position: absolute; */
    bottom: calc(100vw * (calc(10 / var(--width_base))));
    right: calc(100vw * (calc(5 / var(--width_base)))); */
  `;

  return (
    <div key={keyEle} css={cardCss}>
      <div css={cardImagenCss}>
        {data?.correo && (
          <a href={`mailto:${data?.correo}`} css={mailCss}>
            <Icon typeEle="svg-mail" />
            <span className="d-none">{data?.correo}</span>
          </a>
        )}
        {data?.telefono && (
          <a
            className="only-mov"
            href={data?.telefono}
            target="_blank"
            css={phoneCss}
          >
            <Icon typeEle="salas-whatsapp" styleOthers={iconoWhatsappCss} />
            <span className="d-none">{data?.telefono}</span>
          </a>
        )}
        {data?.telefono && (
          <a
            // onClick={() => setShow(!show)}
            // onKeyDown={() => setShow(!show)}
            className="only-desk"
            css={phoneCss}
            tabIndex="0"
            role="button"
            href={data?.telefono}
            target="_blank"
          >
            <Icon
              typeEle="salas-whatsapp"
              id={data?.idWsp}
              styleOthers={iconoWhatsappCss}
            />
            <span className="d-none">{data?.telefono}</span>
          </a>
        )}
        {data?.foto && (
          <Image imagen={data?.foto} styleCss={imagenCss} objectFit="cover" />
        )}
      </div>
      {data?.cargo && (
        <Text
          data={data?.cargo}
          colorText="white"
          align="center"
          styleCss={cargoCss}
        />
      )}
      {data?.nombre && (
        <Text
          data={data?.nombre}
          colorText="white"
          align="center"
          styleCss={nombreCss}
        />
      )}
      {show && (
        <Text
          classEle={'only-desk'}
          data={data?.telefono}
          colorText="white"
          align="center"
          styleCss={nombre2Css}
        />
      )}
    </div>
  );
}
