import * as React from "react"
import { css } from "@emotion/react";
import Text from "../../atoms/Text/Text"
import SliderCarousel from "../../organisms/SliderCarousel/SliderCarousel"

export default function NuestrosBeneficios ({title, data, titlePage}) {

  const textCss = css`
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
    font-family: "Montserrat Bold", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;
  
  const contentCss = css`
    padding-top: calc(100vw * (calc(61 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(64 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw * (calc(1092 / var(--width_base))));
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(30 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;
  const sectionCss = css`
    background-color: #4A4A49;
    @media (max-width: 767px) {
      background-color: #444443;       
    }
  `;

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7px" points="35.86 3.5 4.95 34.41 34.51 63.97" />
      </svg>
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7px" points="3.5 3.5 34.41 34.41 4.84 63.97" />
      </svg>
    </button>
  );


  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: true,
    touchMove: true,
    prevArrow: <SlickArrowLeft/>,
    nextArrow: <SlickArrowRight/>,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          slidesToShow: 1,
        }
      }
    ]
  }

  return(
    <section css={sectionCss}>
      {titlePage && <Text classEle="d-none" data={`Nuestros proyectos ${titlePage}`} typeEle="h2"/>}
      <div css={contentCss}>
        <Text data={title} align="center" colorText="#93BA1F" styleCss={textCss} data-aos="fade-right" data-aos-delay="200"/>
        <SliderCarousel data={data} typeStyle="carousel4" settings={settings} fancybox={false}/>
      </div>
    </section>
  )
}
