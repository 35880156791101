import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Text from "../../atoms/Text/Text"
import NavigationFooter from "../../moleculas/Navegation/NavigationFooter"
import { css } from "@emotion/react"
import Button from "../../atoms/Button/Button"
import Icon from "../../atoms/Icon/Icon"

export default function Footer ({isProyecto,cotizar, ...other}) {

  const data = useStaticQuery(graphql`
      {
          wordpress {
              page(id: "828", idType: DATABASE_ID) {
                  template {
                      ... on Wordpress_Template_Opciones {
                          templateName
                          opciones {
                              direccionFooter
                              linkFacebookFooter
                              linkInstagramFooter
                              linkYoutubeFooter
                              linkLinkedinFooter
                              linkWhatsappHeader
                              textoFooter
                          }
                      }
                  }
              }
              menuItems(where: {location: HCMS_MENU_FOOTER}) {
                  nodes {
                      id
                      label
                      url
                      title
                      target
                      childItems(before: "") {
                          edges {
                              node {
                                  id
                                  label
                                  url
                                  title
                                  target
                              }
                          }
                      }
                  }
              }
              generalSettings {
                  url
              }
          }
      }
  `)

  const page = data?.wordpress?.page;
  const template = page?.template?.opciones;
  const menuItems = data?.wordpress?.menuItems?.nodes;
  const wordPressUrl = data?.wordpress?.generalSettings?.url;

  const footerCss = css`
    background-color: #5E5E5E;
    color: white;
    padding-top: calc(100vw * (calc(40 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(64 / var(--width_base))));
    @media (max-width: 767px) {
      background-color: #444443;
      color: #999;
    }
  `;

  const contentFooterCss = css`
    max-width: calc(100vw * (calc(1024 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
  `;
  
  const textCss = css`
    font-size: calc(100vw * (calc(15 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    ${contentFooterCss};
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      text-align: center;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;
  
  const titleCss = css`
    ${contentFooterCss};
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(23 / var(--width_base))));
    font-family: "Montserrat Bold",sans-serif;
    margin-bottom: calc(100vw * (calc(23 / var(--width_base))));
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      color: #7fbc03;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
    }
  `; 

  const linkwspCss = css`
    position: fixed;
    bottom: calc(100vw * (calc(30 / var(--width_base))));
    right: calc(100vw * (calc(30 / var(--width_base))));
    z-index: 100;

    @media (max-width: 767px) {
      right: 0;
    }

    &.is-project{
      @media (max-width: 767px) {
        top: calc(100vw * (calc(310 / var(--width_base))));
        bottom: initial !important;
      }
    }
  `;

  const linkCotizarCss = css`
    position: fixed;
    right: calc(100vw * (calc(15 / var(--width_base))));
    top: calc(100vw * (calc(240 / var(--width_base))));
    width: calc(100vw * (calc(57 / var(--width_base))));
    height: calc(100vw * (calc(57 / var(--width_base))));
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: calc(100vw * (calc(10 / var(--width_base))));
    line-height: calc(100vw * (calc(13 / var(--width_base))));
    color: #93BA1F;
    font-family: "Montserrat Bold", sans-serif;
    box-shadow: 1px 1px 13px 0 rgb(0 0 0 / 80%);
    z-index: 100;
  `;
  
  const textDirection = css`
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
      border-bottom: calc(100vw * (calc(1 / var(--width_base)))) solid #cfcfcf;
      margin-left: calc(100vw * (calc(15 / var(--width_base))));
      margin-right: calc(100vw * (calc(15 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;

  return(
    <>
      <Button styleEle={linkwspCss} typeEle="linkTarget" link={template?.linkWhatsappHeader} classEle={`${isProyecto && "is-project"}`}><Icon typeEle="whatsapp2"/></Button>
      {
        cotizar &&
        <Button styleEle={linkCotizarCss} typeEle="cotizar" classEle="only-mov">
          ¡COTIZA <br/>AQUÍ!
        </Button>
      }
      <footer css={footerCss} {...other}>
        <Text styleCss={titleCss} data="FUNDAMENTA" colorText="#61A41D"/>
        <NavigationFooter
          direction={template?.direccionFooter}
          facebook={template?.linkFacebookFooter}
          instagram={template?.linkInstagramFooter}
          linkedin={template?.linkLinkedinFooter}
          youtube={template?.linkYoutubeFooter}
          menu={menuItems}
          wordPressUrl={wordPressUrl}
        />
        <Text styleCss={css`${textCss};${textDirection};`} data={template?.direccionFooter}/>
        <Text styleCss={textCss} data={template?.textoFooter}/>
      </footer>
    </>
  )
}


Footer.defaultProps = {
  cotizar: false
}