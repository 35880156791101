import * as React from 'react';
import Search from '../../moleculas/Search/Search';
import { useRef } from 'react';
import Text from '../../atoms/Text/Text';
import { parserString } from '../../../helper/parserString';

export default function SearchProyecto({ titlePage, ...others }) {
  const selectDistrito = useRef(null);
  const selectEstado = useRef(null);
  const selectProyecto = useRef(null);

  const handleSearch = (e) => {
    localStorage.setItem('distrito', selectDistrito?.current?.value);
    const value = parserString(selectDistrito?.current?.value);
    const projectCard = document.querySelectorAll('.project-item');
    projectCard.forEach((element) => {
      if (value === '') {
        element.classList.remove('d-none');
      } else {
        const hastClass = element.classList.contains(value);
        if (hastClass) {
          element.classList.remove('d-none');
        } else {
          element.classList.add('d-none');
        }
      }
    });
  };

  return (
    <section {...others}>
      {titlePage && (
        <Text classEle="d-none" data={`Banner ${titlePage}`} typeEle="h2" />
      )}
      <Search
        selectDistrito={selectDistrito}
        selectProyecto={selectProyecto}
        selectEstado={selectEstado}
        eventEle={handleSearch}
      />
    </section>
  );
}
