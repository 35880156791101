import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import Text from "../components/atoms/Text/Text"
import { css } from "@emotion/react"
import getUri from "../helper/getUri"
import { fbq } from "@hutsoninc/gatsby-plugin-facebook-pixel"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`

export default function GraciasProyecto({ data }) {
  const page = data?.wordpress?.page

  const titleCss = css`
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(25 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
    }
  `
  const textoCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
    }
  `

  const sectionCss = css`
    padding: calc(100vw * (calc(50 / var(--width_base))))
      calc(100vw * (calc(10 / var(--width_base))));
    background-color: #4a4a49;
  `

  const pageUri = getUri(page.uri)

  if (pageUri === "/gracias-eco-prado/") {
    if (typeof window !== "undefined") {
      // fbq('track', 'PageView');
      fbq("track", "CompleteRegistration")
    }
  }

  if (pageUri === "/gracias-eco-style/") {
    if (typeof window !== "undefined") {
      // fbq('track', 'PageView');
      fbq("track", "CompleteRegistration")
    }
  }

  return (
    <LayoutGeneral title={page?.title} slug={page?.uri}>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <section css={sectionCss}>
        <Text
          data="GRACIAS POR CONTACTARNOS"
          typeEle="h2"
          styleCss={titleCss}
          align="center"
          colorText="white"
          data-aos="fade-right"
          data-aos-delay="200"
        />
        <Text
          data="<p>Te enviaremos una cotización personalizada a la brevedad.</p>"
          align="center"
          colorText="white"
          styleCss={textoCss}
          data-aos="fade-right"
          data-aos-delay="300"
        />
      </section>
    </LayoutGeneral>
  )
}

